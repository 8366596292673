.document-proof-status {
  &__icon {
    &::before {
      @include svg-icon("custom");
      content: '\0052';
    }
  }
  &--identity &__icon::before {
    content: '\0052';
  }
  &--address &__icon::before {
    content: '\0053';
    color: color(primary, dark);
  }
  &--address {
    border-color: color(primary, dark);
    &:before {
      background-color: color(primary, dark);
    }
  }
  &--approved,
  &--uploaded {
    border-color: var(--success);

    &::before {
      background: var(--success);
    }
    .document-proof-status__icon::before {
      color: var(--success);
    }
  }
}

