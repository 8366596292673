.responsible-gambling-questionnaire {
  .questionnaire {
    &-content {
      &--full,
      &--compact {
        &__next-button {
          border-radius: 4px;
          @include button-accent;
        }
        &__prev-button {
          border-radius: 4px;
          @include button-secondary;
        }
      }
    }
  }
}
