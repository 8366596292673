.document-proof {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  overflow: hidden;
  width: 230px;
  height: 140px;
  background: #fff;
  border-radius: 13px;
  margin: 10px;

  &__side {
    text-align: center;
    margin: 5px;
    flex: 1;
  }
  &__spinner {
    margin: auto;
  }
  &__reupload {
    text-align: center;
  }
  &__status {
    text-align: center;
    font-size: 18px;
    padding: 5px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
  &__date {
    text-align: center;
    font-size: 10px;
    flex: 1;
  }
  &--status--approved {
    color: var(--success)
  }
  &--status--rejected {
    color: var(--error)
  }
  &--status--uploaded {
    color: var(--warning)
  }
  .imageField-helper {
    margin: 5px;
    text-align: center;
  }
  .imageField {
    &-root {
      flex-direction: column;
      justify-content: flex-end;
      height: 140px;
      width: 100%;
    }
    &-content {
      text-align: center;
      flex: 1;
      padding: 5px;

      .document-proof__side {
        margin: 0;
      }
    }
    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-imageWrapper {
      width: 100%;
      height: 53px;
      flex: 10;
    }
    &-buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
    }
    &-submit,
    &-clear {
      width: 50%;
    }
  }
  .fileField {
    &-root {
      justify-content: center;
    }
  }
}
