.promotions {
  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    @include media-sm {
      margin-bottom: 20px;
    }
    .forfeit-card {
      position: relative;
      display: flex;
      flex-direction: column;
      width: calc(33.33333% - 20px);
      margin: 10px;
      padding: 10px 10px 0;
      @include media-md {
        width: calc(50% - 20px);
      }
      @include media-xs {
        width: calc(100% - 20px);
      }
      &__body {
        position: relative;
      }
      &__custom-banner {
        max-width: 100%;
        height: auto;
      }
      &__footer {
        width: 100%;
        padding: 10px 10px 0;
        .button-accent {
          margin: 0 0 2px;
          padding: 14px 18px;
          width: 100%;
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 1px;
          background-color: color(primary, main);
        }
        &-link {
          display: block;
          margin-bottom: -5px;
          padding: 7px 10px 6px;
          font-size: 14px;
          text-align: center;
          color: var(--promotions-card-link-color);
        }
      }
    }
    .grid-root + .forfeit-card {
      display: block;
      margin: 0 auto;
    }
  }
}
