@keyframes pulsateBadge {
  0% {
    background-color: lighten(color(error), 10%);
    transform: scale(.9) translateZ(0);
  }
  50% {
    background-color: var(--error);
    transform: scale(1) translateZ(0);
  }
  100% {
    background-color: lighten(color(error), 10%);
    transform: scale(.9) translateZ(0);
  }
}
.settings-menu {
  margin: 0;
  padding-inline-start: 0;
  justify-content: center; // [Playdoit]
  list-style-type: none;

  @include isMicrosoftEdge {
    padding: 0;
  }

  .detailsSummary-content > .badge,
  .fpLink > .badge {
    @include size(6px);
    position: relative;
    top: -1px;
    display: inline-block;
    background-color: var(--error);
    border-radius: 50%;
    transform-origin: center;
    animation: pulsateBadge 1s ease-in 0s infinite running;
    backface-visibility: hidden;
    vertical-align: middle;
    align-self: center;
    margin-left: 6px;
    padding: 0;
    opacity: 1;
    transition: all 0.3s;
  }
  .details-expanded > .detailsSummary-root > .detailsSummary-content > .badge {
    opacity: 0;
  }
}
