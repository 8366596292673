.pending-rounds {
  margin-inline: 16px;
  margin-top: 16px;
  .text-center{
    padding: 0 15px;
    .game-list{
      &__title{
        margin: 0;
      }
      &__empty{
        margin: 0;
        background-color: transparent;
        color: var(--body-text-dark-color);
      }
    }
  }
}

.pending-rounds-device {
  font-size: 18px;
  text-align: center;
  color: #fff;
  background: rgba(color(primary, dark), 0.5);

  &__icon {
    margin-top: 20px;
    vertical-align: top;
  }
}

@media (max-width: 1200px){
  .pending-rounds .text-center{
    padding-left: 0;
  }
}
