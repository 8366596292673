/* Layout */

.static-page {
  &__container {
    margin-top: 0; // [Playdoit]
  }
  &__menu {
    padding-right: 15px;

    @include media-md {
      display: none;
    }
  }
  &__content {
    background: var(--static-page-background);
  }
}

/* Menu */

.static-page-menu {
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background: var(--static-menu-background);

  &__item {
    position: sticky;
    position: -webkit-sticky;
    top: calc(#{$header-collapsed-height} + 20px);

    .settings-menu__item-override {
      .details-root {
        border: 0;
        background: none;
        box-shadow: none;

        .detailsSummary-root {
          min-height: 0;
          padding: 0;
        }
        .detailsSummary-content {
          margin: 0;
          padding: 12px 30px;
          color: color(primary, dark);

          &::after {
            content: '';
            position: absolute;
            top: 45%;
            right: 20px;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #333;
          }
          &:hover {
            font-weight: $font-weight-alt;
          }
        }
        &.details-expanded {
          .detailsSummary-content {
            font-weight: $font-weight-alt;

            &::after {
              transform: rotateX(180deg)
            }
          }
        }
      }
      .detailsSummary-content {
        font-size: 16px;
      }
      > a {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 42px;
        padding: 12px 30px;
        font-size: 16px;
        color: var(--static-item-color);
        text-decoration: none;
        transition: color $main-transition-time;
        //[Playdoit] L3mcDdGD-PL fix issues on static-page-menu
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 10px;
          height: 100%;
          background-color: transparent;
        }
        //[Playdoit] L3mcDdGD-PL fix issues on static-page-menu
        &:hover {
          font-weight: $font-weight-alt;
        }
        &.active {
          font-weight: $font-weight-alt;

          &::before {
            background-color: color(primary, main);
          }
        }
      }
      .settings-menu__item-override {
        background: color(static-menu, background);
        border-top: 1px solid color(static-menu, border);

        &:last-of-type {
          border-bottom: 1px solid color(static-menu, border);
        }
      }
    }
  }
}

/* [Playdoit] tx7fWXFo-PL Tutoriales */
.modal-root[class*="page-modal--id-promociones/modals/"],
.modal-root[class*="page-modal--id-picksSemana/modals/"],
.modal-root[class*="page-modal--id-tutoriales/modals/"],
.modal-root[class*="page-modal--id-campaigns/"] {
  .modal-paper .modalContentWrapper .modal-contentRoot > div:last-child{
    margin: 0;
  }
  @include media-md {
    .modal-paper {
      justify-content: initial;
    }
  }
  .modal-contentRoot {
    padding: 0;
  }
  .modal-buttonRoot {

    .modal-buttonLabel {
      color: color(primary, light);
    }
  }
  .promotions-modal {
    &__image > img {
      max-width: 100%;
    }
    &__video {
      padding-top: 20px;
      text-align: center;
    }
    &__content {
      margin: 30px 30px;
      &__title {
        margin: 0;
        font-size: 28px;
      }
      &__text {
        text-align: justify;
        table {
          text-align: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          td, th {
            border: 1px solid black;
            padding: 0 20px;
          }
        }
        ul > li > a {
          text-decoration: none;
        }
      }
    }
  }
}

/* Other */
#static-page__anchor {
  position: relative;
  top: -75px;
}

/* [Playdoit] */
.youtube-video {
  margin-bottom: 10px;
}
.aspect-ratio-4x3,
.aspect-ratio-16x9,
.aspect-ratio-3x1,
.aspect-ratio-75x32 {
  width: 100%;
  height: 0;
  display: block;
  position: relative;
  & > * {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.aspect-ratio-4x3 {
  padding-bottom: 75%;
}
.aspect-ratio-16x9 {
  padding-bottom: 56.25%;
}
.aspect-ratio-3x1 {
  padding-bottom: 33.333%;
}
.aspect-ratio-75x32 {
  padding-bottom: 42.666%;
}

// Mobile App Styles
.mobile-app {
  &__container {
    max-width: 90%;
    margin: 0 auto;
  }
  &__titleStep {
    font-size: 20px !important;
  }
  &__stores {
    text-align: center;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  &__store {
    position: relative;
    a {
      text-decoration: none;
      img {
        // [Playdoit]
        body.dark-theme & {
          filter: grayscale(1) invert(0.8);
        }
      }
    }
    body.desktop &:hover {
       .mobile-app__qr {
        opacity: 1;
        width: 200px;
        overflow: visible;
      }
    }
  }
  &__qr {
    position: absolute;
    bottom: 105%;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 6%), 0 2px 8px 0 rgb(0 0 0 / 15%);
    background-color: #fff;
    border-radius: 5px;
    padding: 5px;
    transition: opacity .3s ease-in-out;
    opacity: 0;
    overflow: hidden;
    span {
      display: block;
      margin-top: -5px;
      color: #666;
    }
  }
  p {
    font-size: 16px;
  }
  @include media-sm {
    details .center-content {
      flex-direction: column;
    }
  }
  .center-content {
    margin-top: 20px;

    & > picture {
      max-width: 20%;
      margin: 0 7px;
    }
    picture > img,
    picture {
      display: block;
    }
    &__store-container {
      padding: 0 30px;
      align-self: flex-start;
        & > p {
          text-align: left;
        }
        & > img {
          display: block;
        }
        & > a {
          color: color(secondary, dark);
          & > span {
            font-size: 18px;
          }
          & > p {
            text-align: left;
          }
          & > img {
            display: block;
            max-width: 20%;
          }
        }
    }
  }
  &__banner {
    img {
      height: calc((100vw - 86px) / 1000 * 316);
      max-height: 356px;
      @include media-sm {
        width: 100%;
      }
    }
  }
}

.aviso-historial {
  &__container {
    &__subTitle {
      font-weight: 600;
    }
    &__flex {
      display: flex;
      justify-content: left;
      &__button {
        text-align: center;
        margin: 0 20px 0 0;
        .button-secondary{
          padding: 5px 20px;
          border: color(secondary, main);
        }
      }
    }
  }
}

/* [Playdoit MA-699] Hide close button, if modal was opened from Mobile application */
.platform--mobileApp {
  .modal-rootButtons {
    display: none;
  }
}
