.promotion-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(33.33333% - 20px);
  margin: 10px;
  padding: 10px 10px 0; // [Playdoit]

  @include media-md {
    width: calc(50% - 20px);
  }
  @include media-xs {
    width: calc(100% - 20px);
  }
  &__body {
    position: relative;
  }
  .promotion-card__custom-banner {
    max-width: 100%;
    height: auto;
  }
  &__footer {
    width: 100%;
    padding: 10px 10px 0 10px;
  }
  &__title {
    @include truncate;
    position: relative;
    margin: 0;
    padding: 6px 14px;
    font-size: 18px;
    text-align: center;
    color: color(promotions-card, title);
    background-color: color(primary, main);
  }
  &__content {
    position: relative;
    padding: 10px 30px 16px;
    font-size: 14px;
  }
  & &__claim-btn {
    margin: 0 0 2px;
    width: 100%;
    //[Plaidoit] ttXFKpF8-PL Custom promotion Card Styles
    padding: 14px 18px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: color(secondary, main);
  }
  &__terms-link {
    display: block;
    margin-bottom: -5px;
    padding: 7px 10px 6px;
    font-size: 14px;
    text-align: center;
    color: var(--promotions-card-link-color);
  }
  &__condition {
    &::before {
      @include svg-icon;
      position: relative;
      top: 4px;
      left: -1px;
      font-size: 20px;
      margin-right: 4px;
    }
  }
  &__condition--cash {
    &::before {
      content: '\0049';
    }
  }
  &__condition--live-casino-bonus {
    &::before {
      content: '\220f';
    }
  }
  &__condition--bia-cash {
    &::before {
      content: '\0056';
    }
  }
  &__condition--promo {
    &::before {
      content: '\0047';
    }
  }
  &__condition--scash {
    &::before {
      content: '\0071';
    }
  }
  &__condition--cashBack {
    &::before {
      content: '\0067';
    }
  }
  &__condition--free-spin {
    &::before {
      content: '\006a';
    }
  }
  &__condition--deposit-amount {
    &::before {
      content: '\0052';
    }
  }
  &__condition-bonus-validity {
    &::before {
      content: '\0069';
    }
  }
  &__turnover-amount {
    &::before {
      content: '\0046';
    }
  }
  p {
    margin: 0;
  }
}
