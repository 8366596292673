.limit-amount {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 300px;
  height: 100%;
  margin: 10px auto;

  &__title {
    height: 32px;
    margin-right: 90px;
    text-align: center;
    text-transform: uppercase;
  }
  &__title-content {
    font-weight: $font-weight-alt;
  }
  &__renew,
  &__amount {
    padding: 0 9px;
    text-align: center;
    background: var(--limit-amount-background);
    flex: 1;
  }
  &__amount-input {
    align-items: center;
    .input-before {
      font-size: .8rem;
    }
    &.input-disabled > .input-before,
    input.input-inputDisabled {
      color: var(--input-label-disabled-color);
    }
  }
  &__amount-field {
    margin: 15px 0 0;

    .field-helperHint {
      p {
        margin: 0;
        line-height: 1;
      }
    }
  }
  &__progress {
    position: absolute;
    top: -7px;
    right: 0;
    width: 90px;
    padding: 5px;
    font-weight: $font-weight-alt;
    border-radius: 50%;
    background: var(--limit-amount-background);

    &.unlimited {
      font-size: 24px;
      font-weight: $font-weight-base;
    }
    .circularProgress-back {
      stroke: var(--limit-amount-progress-back);
    }
    .circularProgress-path {
      stroke: var(--limit-amount-progress-path);
    }
    .available-limit-amount__title {
      position: absolute;
      left: 50%;
      bottom: 15px;
      transform: translateX(-50%);
      font-size: 12px;
      text-transform: initial;
      font-weight: $font-weight-base;
      color: var(--limit-amount-color);
    }
  }
  &__revert-button,
  &__remove-button {
    border-radius: 50%;
    min-height: 26px;
    min-width: 26px;
    padding: 0;
    display: none; // [Playdoit]
    justify-content: center;
    align-items: center;
    color: color(primary, dark);

    &:not(.button-loading) .button-label:before {
      @include svg-icon;
    }
  }
  &__revert-button .button-label:before {
    content: '\007d';
  }
  &__remove-button .button-label:before {
    content: '|';
  }
  &__confirm {
    width: 100%;
    font-size: 12px;
    font-weight: $font-weight-base;
    padding: 7px 5px;
    min-height: 0;
    margin-top: 6px;
    text-transform: none;
    min-width: 50px;

    &.button-disabled {
      background: #d4d4d4;
      color: #a9a9a9;
    }
  }
}
