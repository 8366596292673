.resume-modal--frame {
  .resume-form,
  .login-modal__form-wrapper {
    height: 100%;
  }
  .modal-contentRoot {
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
// [Playdoit]

.button-default.login-modal {
  &__sign-up {
    color: color(custom, main);
    & > span.button-label:before {
      background-color: color(custom, main);
    }
  }
  &__forgot-password {
    top: 10px;
    margin-right: 5px;
    font-size: 14px;
    color: color(primary, dark);
    &:hover {
      color: color(primary, main);
    }
  }
}
