.not-found-page {
  display: flex;
  flex-grow: 1;
  align-items: center;
  min-height: calc(100vh + #{$header-expanded-height});
  margin-top: -#{$header-expanded-height};
  @include webp-bg-img('/cms/img/bg.webp', '/cms/img/bg.jpg');
  background: {
    position: center;
    repeat: no-repeat;
    size: cover;
  }
  @include media-md {
    min-height: calc(100vh + #{$header-collapsed-height});
    margin-top: -#{$header-collapsed-height};
  }

  &__content {
    max-width: 670px;
    margin: 0 auto;
    padding: 15px;
    text-align: center;
  }
  &__title {
    color: #fff;
    font-size: 31px;
    font-weight: $font-weight-base;
  }
  &__text {
    margin-bottom: 40px;
    color: #fff;
    font-size: 16px;
  }
  &__link {
    display: inline-block;
    padding: 16px 40px;
    font-size: 21px;
    text-decoration: none;
    cursor: pointer;
  }
}
