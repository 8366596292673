/* [Playdoit] Promotions-static */
.promotions-static {
  max-width: 1920px;
  padding: 0;
  margin: 0 -12.5px;
  width: auto;
  border-radius: 5px;

  @include media-md {
    margin: 0;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
    @include media-md {
      justify-content: space-between;
    }
  }

  &__categories {
    display: flex;
    flex-wrap: wrap;
    margin: 0 12.5px 15px;
    @include media-md {
      margin: 0px 0 15px 0;
    }
    @include media-xs {
      margin: 10px 0 10px 0;
    }
    button {
      text-transform: uppercase;
      margin-right: 5px;
      margin-bottom: 5px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-item {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    width: calc(33.33333% - 25px);
    margin: 0 12.5px 15px;
    border-radius: 5px;
    background-color: var(--static-promotion-item-background);

    @media (max-width: 1025px) {
      margin-left: initial;
      margin-right: initial;
    }
    @include media-md {
      width: calc(50% - 10px);
      margin: 0 0 15px;
    }
    @media (max-width: 650px) {
      width: 100%;
      overflow: hidden;
    }

  }

  &__image {
    width: 100%;
    min-height: 148px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    @media (max-width: 1286px) and (min-width: $size-md) {
      min-height: calc((((100vw - 30px) * 0.75) - 50px) / 6);
    }

    @media (max-width: $size-md - 1px) and (min-width: 651px) {
      min-height: calc((100vw - 52px) / 4);
    }

    @media (max-width: 650px) {
      min-height: calc((100vw - 30px) / 2);
    }
  }

  &__body {
    p {
      padding: 0 5px;
      font-size: 17px;
      font-weight: 600;
      color: var(--static-promotion-item-text-color);
      text-align: center;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    font-size: 15px;
    font-weight: 600;
    border-top: 3px solid var(--static-promotion-item-footer-border);

    &__read-more {
      color: var(--static-promotion-item-readmore-color);
    }

    &__deposit-link,
    &__deposit-link:hover {
      color: color(secondary, main);
    }
  }
}
