.home-slider {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;

  &-wrapper {
    &, & img {
      min-height: 28vw;
      max-height: 28vw;
    }

    .mobile & {
      &, & img {
        min-height: 46vw;
        max-height: 46vw;
      }
    }
    .mobile [data-url='/'] &,
    .mobile [data-url^='/games'] &,
    .mobile [data-url^='/en-vivo'] &,
    .mobile [data-url^='/historial'] &,
    .mobile [data-url^='/bonuses'] &,
    .mobile [data-url^='/new-prelive'] &
    {
      &, & img {
        min-height: 20vw;
        max-height: 20vw;
      }
    }
  }
  .swiper-slide {
    display: inline-block;
    overflow: hidden;
    cursor: pointer;

    
    picture > img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }
}

