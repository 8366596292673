.withdrawal-form {
  &__title {
    display: inline-block;
    margin: 25px 0 10px;
    padding-bottom: 2px;
    font-size: 16px;
    font-weight: $font-weight-alt;
    border-bottom: 6px solid color(secondary, main);

    p {
      margin: 0;
    }
    &--method {
      margin-top: 0;
    }
  }
  &__sub-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0 5px;
    padding-bottom: 5px;
    font-size: 16px;
    border-bottom: 2px solid color(secondary, main);

    strong {
      font-size: 20px;
      font-family: 'Roboto'; // [Playdoit]
    }
    .exchange {
      display: block;
      text-align: right;
    }
    p {
      margin: 0;
      font-weight: $font-weight-alt;
    }
  }

  .notification-info {
    border-color: #d8ab41;
    &::before {
      content: 'D';
      background-color: #d8ab41;
    }
  }
  &__fields {
    .gridItem-root[class*='withdrawal-form__field--']:not(:only-child) {
      max-width: 50%;
      flex-basis: 50%;
      margin: 0 -5px;
      padding: 0 5px;
    }
  }
}
.withdrawal-summary {
  &__bonuses-title {
    p {
      margin-bottom: 0;
      line-height: 1;
    }
  }
  &__deduct,
  &__receive {
    max-width: 45%;

    @include media-xs {
      max-width: 100%;
    }
    .group-root {
      p {
        margin: 0;
        line-height: 2;
        border-bottom: 1px solid #ccc;
      }
      strong {
        float: right;
      }
    }
    .exchange {
      display: block;
      text-align: right;
    }
  }
  &__fee {
    margin-top: 30px;
    font-size: 13px;
    color: var(--body-text-dark-color); // [Playdoit]

    @include media-xs {
      margin: 10px 0 20px;
    }
    &::before {
      @include svg-icon;
      content: '\0044';
      padding-right: 5px;
      vertical-align: top;
      color: var(--warning);
    }
  }
}

/** Bank account */
.withdrawal-form--1001 {
  @media (min-width: 480px) {
    .withdrawal-form__field {
      &--bankAccount,
      &--bankName,
      &--bankAccountOwnerName {
        max-width: 45%;
        flex-basis: 45%;
      }
    }
  }
}
/* **/

/** IBAN */
.withdrawal-form--1011 {
  @media (min-width: 480px) {
    .withdrawal-form__field {
      &--ibanAccountOwnerName,
      &--address,
      &--ibanAccount,
      &--ibanBankName,
      &--bankLocation,
      &--bankCity,
      &--beneficiaryCity,
      &--beneficiaryCountry,
      &--beneficiaryState,
      &--beneficiaryZipCode,
      &--beneficiaryStreet,
      &--beneficiaryHouseNumber,
      &--beneficiaryAddress,
      &--ibanBSB,
      &--ibanBic {
        max-width: 45%;
        flex-basis: 45%;
      }
    }
  }
}
/* **/

.withdrawal-form__frame {
  width: 100%;
  height: 666px;
  border: none;
}
.modal-root {
  .modal-paper {
    .modalContentWrapper {
      .modal-contentRoot {
        .withdrawal-form__back {
          position: absolute;
          top: 40px;
          left: 80px;
          display: flex;
          min-width: 0;
          min-height: 0;
          padding: 0;
          font-size: 14px;
          border: 0;
          background: none;
          overflow: hidden;
          transition: all 0.2s;

          .button-label {
            transform: translateY(-100%);
            transition: transform .2s;
          }
          &::before {
            @include svg-icon;
            content: '\0062';
            margin-right: 5px;
            font-size: 15px;
            transform: rotate(180deg);
          }
          &:hover {
            color: color(secondary, main);

            .button-label {
              transform: translateY(0);
            }
          }
        }
      }
    }
  }
}

.revoke-confirmation-modal {
  .modal-paper {
    width: 550px;
  }
}
