@mixin white-header {
  background: var(--header-background);

  & .button-default {
    color: inherit;

    &:hover .button-label span::after {
      background: color(primary, dark);
    }
    &.header__resume .button-label span::after {
      background: color(primary, dark);

      @media (max-width: 360px) {
        background: none;
      }
    }
  }
  &.header .header__balance {
    @include media-xs {
      &.balance__button::before {
        color: color(primary, light); // [Playdoit]
      }
    }
    @include media-md {
      color: color(primary, light); // [Playdoit]

      &::after {
        border-top-color: color(primary, light); // [Playdoit]
      }
    }
  }
  & .header__timer-area {
    color: color(primary, dark);
  }
}

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 25;
  height: $header-expanded-height;
  opacity: 1;
  transition: background $main-transition-time,
              border-bottom $main-transition-time,
              height $main-transition-time;

  @include media-md {
    height: $header-collapsed-height;
  }
  .button-default {
    color: color(primary, light);
    transition: all $main-transition-time;

    &::after {
      display: none;
    }
    .button-label {
      span {
        position: relative;

        &::after {
          content: '';
          display: block;
          height: 1px;
          position: absolute;
          right: 0;
          bottom: -2px;
          left: 0;
          background-color: transparent;
          transition: $main-transition-time;
        }
      }
    }
    &:hover .button-label span::after {
      background: color(primary, dark);
    }
  }
  .button-accent {
    text-transform: uppercase; //[Playdoit] wvIw2AUM-PL Uppercase in depósito
    min-width: 0;
    padding-right: 20px;
    padding-left: 20px;
  }
  #root[data-url='/responsible-gambling'] &,
  #root:not([data-static-url]):not([data-url='/']):not([data-url='/live']):not([data-url^='/group/']):not([data-url$='-games']) & {
    background-color: color(primary, main);
  }
  & .button-default {
    color: color(primary, light);

    &:hover .button-label span::after {
      background: color(primary, light);
    }
    &.header__resume .button-label span::after {
      background: color(primary, light);

      @media (max-width: 360px) {
        background: none;
      }
    }
  }
  &--scrolled {
    @include white-header;
    height: $header-collapsed-height;

    .header__container {
      padding-top: 4px;
      padding-bottom: 4px;
    }
    .header__group-left,
    .header__group-center,
    .header__group-right {
      transform: scale(0.8);
    }
    // TODO: change it to make more generic
    .header__group-left {
      margin-left: -4%;
    }
    .header__group-right {
      margin-right: -4%;
    }
    .header__timer {
      transform: scale(1.24);
    }
  }
  &__container {
    display: flex;
    height: auto;
    margin: auto;
    padding-top: 9px;
    padding-bottom: 9px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    @include media-md {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
  &__group-left,
  &__group-center,
  &__group-right {
    transition: all $main-transition-time;
  }
  &__group-left {
    display: flex;
    flex: 1;
    justify-content: center;
  }
  .button-root {
    font-size: 18px;
    line-height: 100%;
    font-weight: $font-weight-base;

    .button-label {
      &::before {
        @include svg-icon;
        content: '\0076';
        padding-right: 3px;
        font-size: 18px;
      }
    }
    @include media-md {
      font-size: 16px;
    }
  }
  &__logo {
    display: block;
    width: 220px;
    transition: all $main-transition-time;
    color: var(--header-color);

    img {
      color: inherit;
      display: block;
      width: 100%;
    }
    @include media-md {
      width: 100px;
    }
  }
  &__group-right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }
  & &__menu {
    margin-right: auto; // [Playdoit]
    padding-left: 0;

    .button-label::before {
      content: '\0076';
    }
  }
  & &__casino {
    margin-right: auto;
    padding-left: 0;

    .button-label::before {
      content: '\005e';
    }

    &--live {
      .button-label::before {
        content: '\005d';
      }
    }
  }
  & &__promotions {
    margin-right: auto;
    padding-left: 0;

    .button-label::before {
      content: '\0056';
      margin-top: -4px;
    }
  }
  & &__login .button-label::before {
    content: '\006d';
  }
  & &__registration {
    padding-right: 0;
  }
  & &__registration .button-label::before {
    content: '\0045';
  }
  & &__resume {
    padding-right: 0;
    text-transform: uppercase;
    font-weight: $font-weight-base;

    @include media-xs {
      font-size: 12px;
      padding: 10px;
    }
    .button-label {
      &::before {
        content: '';
      }
      span::after {
        background: color(primary, light);
      }
      @media (max-width: 360px) {
        line-height: 1.4;
        text-decoration: underline;
        text-underline-position: under;

        span::after {
          background: none;
        }
      }
    }
    &:hover .button-label span::after {
      background: transparent;
    }
  }
  & &__verification {
    margin-right: auto;
    padding-left: 0;

    .button-label::before {
      content: '\0061';
      color: var(--error);
    }
  }
  & &__deposit {
    @include media-md {
      display: none;
    }
    .button-label::before {
      content: '\0049';
      padding-right: 10px;
    }
  }
  & &__balance {
    padding-right: 30px;
    border-right: 1px solid #333;
    white-space: nowrap;

    &::after {
      content: '';
      position: absolute;
      top: 45%;
      right: 0;
      width: 0;
      height: 0;
      margin-right: 12px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid color(primary, light);
    }
    @include media-md {
      color: #fff;
      border-right: none;
      background: none;

      &::after {
        border-top-color: #fff;
      }
    }
    @include media-xs {
      padding: 0 18px;

      &::after {
        right: -12px;
      }
    }
    @media (max-width: 330px) {
      font-size: 12px;
    }
    .button-label::before {
      display: none;
    }
  }
  &__icon-links-group {
    display: flex;
    justify-content: center;
    margin-left: auto;
    padding-left: 18px;

    .button-root {
      padding-right: 5px;
      padding-left: 5px;
    }
    @include media-xs {
      display: none;
    }
  }
  & &__promotions--icon .button-label::before {
    content: '\0056';
    margin-top: -7px;
  }
  & &__free-spins--icon .button-label::before {
    content: '\006a';
  }
  & &__tournaments {
    margin-left: auto;

    @include media-md {
      padding-right: 0;
    }

    .button-label::before {
      content: '\006e';
    }
  }

  &__timer {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    transition: all .3s;

    &-area {
      padding: 8px 0;
      color: color(primary, light);

      .is-logged-in & {
        cursor: help;
      }
    }

    .session-time__title {
      display: inline-block;
      font-size: inherit;
      color: color(text, main);
      margin-right: 4px;

      &:first-letter {
        text-transform: uppercase;
      }

      &:after {
        content: ':';
      }
    }
  }
  @include media-sm {
    & &__menu,
    & &__casino,
    & &__tournaments,
    &__verification,
    &__promotions,
    &__betting {
      .button-label {
        font-size: 0;

        span {
          font-size: 0;
        }
        &::before {
          font-size: 20px;
        }
      }
    }
    &__logout,
    &__registration {
      display: none;
    }
    & &__deposit,
    & &__login {
      padding-top: 0;
      padding-right: 0;
      padding-bottom: 0;
      font-size: 14px;
      background: none;

      .button-label {
        &::before {
          padding-right: 0;
        }
      }
    }
    & &__deposit {
      .button-label {
        display: flex;
        flex-direction: column;
      }
    }
    & &__login {
      .button-label {
        font-size: 0;
      }
    }
  }
}
