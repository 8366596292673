.post-login-page {
  margin-top: 15px;
}

/* Menu */

.post-login__menu {
  padding-right: 15px;
}
.post-login-menu {
  position: sticky;
  position: -webkit-sticky;
  top: $header-collapsed-height;

  &_wrap {
    height: 100%;
    background: var(--post-login-menu-wrap-background);
  }
  .settings-menu__item-override {
    .details-root {
      border: 0;
      border-bottom: 1px solid var(--post-login-settings-menu-border-color);
      background: none;
      box-shadow: none;

      .detailsSummary-root {
        min-height: 0;
        padding: 0;
      }
      .detailsSummary-content {
        margin: 10px 0;
        padding: 7px 30px;
        color: var(--body-text-dark-color);

        &::after {
          content: '';
          position: absolute;
          top: 45%;
          right: 20px;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid var(--post-login-menu-link-color);
        }
      }
      &.details-expanded {
        background: var(--post-login-settings-menu-expanded);

        .detailsSummary-content {
          font-weight: $font-weight-alt;

          &::after {
            transform: rotateX(180deg)
          }
        }
      }
    }
    .settings-menu {
      width: 100%;
      background: var(--post-login-settings-menu-background);
      &__item-override {
        border-top: 1px solid var(--post-login-settings-menu-border-color);
        & > a {
          min-height: 60px;
        }
      }
    }
    > a {
      z-index: 2;
      min-height: 50px;
      padding: 7px 30px;

      &::before {
        @include svg-icon;
        content: '\0045';
        position: absolute;
        left: 45px;
        font-size: 18px;
        transition: color $main-transition-time;
      }
      &[href*="self-exclusion"] {
        &::before {
          content: '\0024';
        }
      }
      &[href*="questionnaire"] {
        &::before {
          content: '\003f';
        }
      }
      &[href*="transactions/promo"] {
        &::before {
          content: '\0047';
        }
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        transition: border $main-transition-time;
      }
      &.active,
      &:hover {
        color: var(--link-default-color);
        transition: color $main-transition-time;

        &::before {
          color: var(--link-default-color);
          transition: color $main-transition-time;
        }
        &::after {
          z-index: -1;
          border-left: 8px solid color(primary, main);
          background: var(--post-login-menu-wrap-background);
        }
      }
      &.active {
        &::after {
          width: calc(100% + 7px);
        }
      }
    }
  }

  a {
    position: relative;
    display: flex;
    align-items: center;
    color: var(--post-login-menu-link-color);
    text-decoration: none;
  }
  @include media-md {
    display: none;
  }
}

/* Content */

.post-login-content-wrap {
  &--account-validation,
  &--sof-validation {
    display: flex;
  }
  &:not(&--account-validation):not(&--sof-validation):not(&--limits):not(&--documents) {
    padding-top: 20px;
  }
  &--profile {
    padding-top: 0 !important;
  }
}
.post-login-content {
  flex-direction: column;

  h1 {
    text-align: center;
    font-weight: 500; // [Playdoit]
    color: var(--body-text-dark-color); // [Playdoit]
  }
  .container {
    flex: 1;
  }
  .fields-container {
    width: calc(100% + 80px);
    margin: 0 -40px;

    @include media-xs {
      width: calc(100% + 40px);
      margin: 0 -20px;
    }
    .gridItem-root {
      padding: 0 40px;

      @include media-xs {
        padding: 0 20px;
      }
    }
  }
  .item {
    background-color: var(--post-login-content-background);
  }
}
.button-wrapper {
  .button-root {
    margin: 20px 0;
  }
}
