.verification-form {
  &__footer {
    background: none;
  }
  &__row {
    &--footer {
      &:last-child {
        margin-top: -8px;
        padding-top: 0;
      }
    }
  }
  &__button {
    &--secondary,
    &--cancel {
      box-shadow: none;
      &:hover {
        color: color(primary, light);
      }
    }
  }
  &__type {
    .radio-checked + .formControlLabel-label {
      background: color(primary, main);
      color: color(primary, light);
    }
  }
}
