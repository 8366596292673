.typography {
  table.rules {
    margin: 5px;
    background-color: #e5e6e8;
    & > thead {
      padding: 10px;
      font-size: 16px;
      text-align: center;
    }
    & td {
      padding: 10px;
      border: 1px solid color(primary, light);
      &::before {
        font-size: 18px;
        color: color(primary, main);
      }
    }
    & .positive::before {
      content: '\2713';
      color: color(secondary, main);
    }
    & .negative::before {
      content: '\292C';
    }
    & .arrow::before {
      content: '\27A3';
      padding-right: 5px;
    }
    & .ellips::before {
      content: '\22EE';
      color: color(secondary, dark);
    }
    & .return::before {
      content: '\2923';
      color: color(secondary, dark);
    }
  }
  summary {
    outline: none;
    line-height: 30px;
    font-size: 16px;
    transition: all .4s;
    cursor: pointer;
  }
  details[open] summary {
    position: relative;
    &::after {
      position: absolute;
      top: 12px;
      right: 0;
      content: '\0030';
      @include svg-icon("custom");
      color: var(--link-default-color);
    }
  }
}
