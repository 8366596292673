/* Roboto */

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Icons */
@font-face {
  font-family: 'Glyphter';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('/cms/fonts/glyphter/Glyphter.woff') format('woff');
}

/* Playdoit icons */
@font-face {
  font-family: 'Glyphter-custom';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('/cms/fonts/glyphter-playdoit-font/Glyphter-custom.woff2') format('woff2');
}

// [Playdoit] Custom fonts Titillioum Web
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''), url('/cms/fonts/Titillium-web/TitilliumWeb-Regular.woff2') format('woff2');
}
/* titillium-web-600 - latin-ext_latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(''), url('/cms/fonts/Titillium-web/TitilliumWeb-SemiBold.woff2') format('woff2');
}

