.protection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 40px;
  // [Playdoit] custom styles
  background: no-repeat center center fixed;
  @include webp-bg-img('/cms/img/background.webp', '/cms/img/background.jpg');
  background-size: cover;
  // [Playdoit] custom styles
  @include media-sm {
    align-items: center;
    @include webp-bg-img('/cms/img/backmobile.webp', '/cms/img/backmobile.jpg');
  }
  &__logo {
    display: block;
    // [Playdoit] custom styles
    max-width: 25%;
    margin: auto 0 auto 9%;
    @include media-sm {
      max-width: 85%;
      margin: 0 auto;
    }
    @include media-md {
      max-width: 65%;
      margin: 0 auto;
    }
  }
  &__content {
    flex: 0 0 100%;
    margin: auto 0; // [Playdoit]
    text-align: center;
    justify-content: left;
    @include media-sm {
      p {
        font-size: 32px;
      }
    }
  }
  &__title,
  &__text {
    color: #fff;
  }
  &__title {
    margin-bottom: 0;
    font-size: 40px;
    font-weight: $font-weight-base;
    text-transform: uppercase;
  }
  &__text {
    font-size: 20px;
  }
  &__license {
    max-width: 75%;
    margin: 0 auto;
    color: #fff;
    font-size: 14px;
    text-align: center;

    @include media-sm {
      max-width: none;
    }

    &--button {
      @include media-sm {
        padding-bottom: 70px;
      }
    }
  }
  &__button {
    width: 30vh;
    cursor: pointer;
    opacity: .5;
    transition: .2s;
    &:hover {
      opacity: 1;
    }
  }

  .adornment-password {
    border-radius: 0;
    border-bottom: solid 1px color(primary, dark);
    color: var(--adornment-password-color);
    background-color: var(--protection-adornment-password-background);
  }
}
