.aside-menu {
  display: grid;
  grid-template-areas: "locale close"
                       "payment payment"
                       "menu menu"
                       "logout logout";
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto 1fr auto;
  max-width: 100vw;
  width: 384px;
  height: 100%;
  font-size: 18px;
  overflow-y: auto;
  background: var(--aside-menu-background);

  .mobile &,
  .tablet & {
    @include customVh;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  &__locale {
    grid-area: locale;
    justify-self: start;
    margin: 28px 17px;
  }
  &__deposit {
    padding: 20px 22px;
    width: 100%;
    font-size: 18px;
    // [Plaidoit] uV7IYonY-PL Fixed styles problem of asidemenu
    font-weight: $font-weight-alt;
    text-transform: uppercase;
  }
  &__withdrawal {
    display: block;
    margin: 0 auto;
    font-weight: $font-weight-base;
  }
  &__resume {
    display: block;
    margin: 0 auto;
    font-weight: $font-weight-base;
  }
  &__login {
    padding: 20px 22px;
    width: 100%;
    font-size: 18px;
     // [Plaidoit] uV7IYonY-PL Fixed styles problem of asidemenu
    font-weight: $font-weight-alt;
    text-transform: uppercase;
  }
  &__logout {
    grid-area: logout;
    padding: 0 0 18px 46px; // margin is working incorrectly with grid-area in Firefox

    .is-ios & {
      padding-bottom: 100px;
    }
  }
  &__sign-in {
    display: block;
    margin: 0 auto;
    font-weight: $font-weight-base;
  }
  &__links-wrapper {
    grid-area: menu;
    flex-direction: column;
    grid-column: span 2;
    width: 100%;
    padding-bottom: 30px;
  }
  &__hot-links {
    grid-area: payment;
    grid-column: span 2;
    width: 100%;
    padding: 0 22px;
  }
  &__close {
    @include close-button();
    position: relative;
    grid-area: close;
    margin: 32px 16px;
    cursor: pointer;

    &::before {
      font-size: 18px;
    }
  }
  a {
    text-decoration: none;
    color: var(--aside-menu-link-color);
    // transition: color $main-transition-time;

    // &:hover,
    // &.active {
    //   text-decoration: none;
    //   color: color(main-menu, link-active);
    // }

    // &::before {
    //   transition: color $main-transition-time;
    // }
  }
  //TODO: refactor the remaining part
  .detailsSummary-root {
    padding: 0;
    min-height: 0;
  }
  .detailsSummary-content {
    margin: 0;
  }
  .settings-menu__item-override {
    & > a {
      margin-left: 22px;
    }
    .settings-menu__item-override > a {
      margin-left: 0;
    }
    &--ipoker > a {
      margin-top: 0px !important;
      padding: 22px 20px 25px 46px;
    }
  &--menuAsideGameGroupsLive {
    border-top: 1px solid var(--aside-menu-border-color);
  }
    & a {
      position: relative;
      display: block;
      margin-top: 14px;
      padding-left: 24px;
      cursor: pointer;
      // [Playdoit]
      &:hover {
        text-decoration: underline;
      }
      &.active {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 8px;
          display: block;
          width: 4px;
          height: 100%;
          // background-color: color(primary, main);
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
    .details-root {
      padding: 22px 20px 25px 46px;
      border: none;
      border-bottom: 1px solid var(--aside-menu-border-color);
      box-shadow: none;
      background: var(--aside-menu-background);

      &.details-expanded {
        .detailsSummary-content {
          font-weight: $font-weight-alt;

          &::after {
            transform: rotateX(180deg)
          }
        }
      }

      &.menuAsideResponsibleGambling {
        margin-top: 14px;
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }
    .detailsSummary-content {
      &::after {
        content: '';
        position: absolute;
        top: 45%;
        right: 20px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid var(--aside-menu-link-color);
      }
    }
  }
  [class*='menuAsideGameGroups'] {
    .settings-menu__item-override {
      a {
        margin-bottom: 22px;
        margin-left: 40px;

        &::before {
          @include svg-icon;
          content: '\0078';
          position: absolute;
          top: 1px;
          left: -13px;
          color: var(--aside-menu-link-color);
          font-size: 20px;
        }
        &.active {
          &::after {
            display: none;
          }
          &::before {
            color: var(--link-default-color);
          }
        }
        &[href*='favourite'] {
          &::before {
            content: '\0058';
            font-size: 22px;
          }
        }
        &[href*='popular'] {
          &::before {
            content: '\0079';
          }
        }
        &[href*='slots'] {
          &::before {
            content: '\007a';
          }
        }
        &[href*='jackpot'] {
          &::before {
            content: '\0030';
          }
        }
        &[href*='table'] {
          &::before {
            content: '\0031';
          }
        }
        &[href*='betting'] {
          &::before {
            content: '\002c';
          }
        }
        &[href*='liveBlackjack'] {
          &::before {
            content: '\002d';
          }
        }
        &[href*='livePoker'] {
          &::before {
            content: '\002e';
          }
        }
        &[href*='liveRoulette'] {
          &::before {
            content: '\002f';
          }
        }
        &[href*='liveBaccarat'] {
          &::before {
            content: '\005b';
          }
        }
        &[href*='liveAll'] {
          &::before {
            content: '\005c';
          }
        }
        &[href*='promo'] {
          &::before {
            content: '\007e';
          }
        }
      }
    }
  }
}
