.deposit-form {
  &__title {
    display: inline-block;
    margin: 25px 0 10px;
    padding-bottom: 2px;
    font-size: 16px;
    font-weight: $font-weight-alt;
    border-bottom: 6px solid color(primary, main);

    p {
      margin: 0;
    }
    &--method {
      margin-top: 0;
    }
  }
  .notification-info {
    border-color: #d8ab41;
    &::before {
      content: 'D';
      background-color: #d8ab41;
    }
  }
  &__sub-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0 5px;
    padding-bottom: 5px;
    font-size: 16px;
    border-bottom: 2px solid color(primary, main);

    strong {
      font-size: 20px;
      font-family: 'Roboto'; // [Playdoit]
    }
    .exchange {
      display: block;
      text-align: right;
    }
    p {
      margin: 0;
      font-weight: $font-weight-alt;
    }
  }
  &__ukgc-message {
    text-align: justify;
  }
  &__fields-title {
    p {
      margin: 0;
    }
  }
  &__fields {
    .gridItem-root[class*='deposit-form__field--']:not(:only-child) {
      max-width: 50%;
      flex-basis: 50%;
      margin: 0 -5px;
      padding: 0 5px;
      @include media-sm {
        max-width: 100%;
        flex-basis: 100%;
        margin: 0;
        padding: 0;
      }
    }
    .gridItem-root[class*='deposit-form__field--customer_cc_number']:not(:only-child) {
      @include media-sm {
        max-width: 100%;
        flex-basis: 100%;
        margin: 0;
        padding: 0;
      }
    }
  }
  // [Playdoit]
  .button-accent {
    background-color: color(accent, secondary);
    &:hover {
      background-color: #229058;
    }
  }
}
.deposit-form__frame,
.resume-form__frame {
  width: 100%;
  height: 90vh;
  border: none;

  // voucher
  &.deposit-form__frame_provider_150001 {
    display: none;

    & + .deposit-step-back {
      display: none !important;
    }
  }

  // paynet
  &_provider_900002 {
    height: calc(100vh + 880px);

    @include media-md {
      height: 1530px;
    }
    @include media-xs {
      height: 2100px;
    }
    @media (max-width: 360px) {
      height: 2170px;
    }
  }

  // openpay
  &_provider_900003 {
    height: calc(100vh + 215px);

    @include media-md {
      height: 1000px;
    }
    @include media-xs {
      height: 1225px;
    }
    @media (max-width: 360px) {
      height: 1275px;
    }
  }

  &--authentication {
    display: none;

    &_visible {
      height: 100%;
      width: 100%;
      border: none;
      min-height: 500px; // Playdoit [T2-3475]
    }
  }
}
.deposit-form__frame {
  display: block;
  @include media-sm {
    min-height: calc(100vh - 155px);
  }

  &.loading {
    height: 0;
  }
  & + .deposit-form__circular-progress {
    display: none;
    margin-left: 50%;
    transform: translate(-50%, 0);
  }
  &.loading + .deposit-form__circular-progress {
    display: block;
  }
}
.payment-method-field {
  .select-select {
    display: inline-flex;
    width: 100%;

    span {
      display: inline-block;
    }
  }
}
.payment-method-field__img {
  width: 70px;
  max-height: 30px;
  margin-right: 5px;

  body.dark-theme & {
    filter: brightness(0) invert(1);
  }
}
.payment-method-field__img-country {
  width: auto;
  max-height: 30px;
  margin-right: 5px;
}
.deposit-summary {
  &__bonuses-title {
    p {
      margin-bottom: 0;
      line-height: 1;
    }
  }
  &__turnover-title {
    p {
      margin: 0;
    }
    em {
      font-size: 12px;
      font-style: normal;
      color: color(input, hint);
    }
  }
  &__deduct,
  &__receive {
    max-width: 45%;

    @include media-xs {
      max-width: 100%;
    }
    .group-root {
      p {
        margin: 0;
        line-height: 2;
        border-bottom: 1px solid #ccc;
      }
      strong {
        float: right;
      }
    }
    .exchange {
      display: block;
      text-align: right;
    }
  }
  &__fee {
    margin-top: 30px;
    font-size: 13px;
    color: color(text, dark); // [Playdoit]

    @include media-xs {
      margin: 10px 0 20px;
    }
    &::before {
      @include svg-icon;
      content: '\0044';
      padding-right: 5px;
      vertical-align: top;
      color: color(warning);
    }
  }
}
.deposit-limit-confirmation {
  margin-top: 30px;
  text-align: center;
}
.deposit-step-back,
.resume-step-back {
  position: absolute;
  top: 40px;
  left: 80px;
  display: flex;
  min-width: 0;
  min-height: 0;
  padding: 0;
  font-size: 14px;
  border: 0;
  background: none;
  overflow: hidden;
  transition: all 0.2s;

  @include media-sm {
    left: 15px;
  }
  .button-label {
    transform: translateY(-100%);
    transition: transform .2s;
  }
  &::before {
    @include svg-icon;
    content: '\0062';
    margin-right: 5px;
    font-size: 15px;
    transform: rotate(180deg);
  }
  &:hover {
    color: color(secondary, main);
    background: none;

    .button-label {
      transform: translateY(0);
    }
  }
}
.supported-currency-field .field-helperHint,
.exchange {
  font-size: 12px;
  color: var(--input-hint);
}

// OXXOPAY, SendAbono
.deposit-form--400002,
.deposit-form--1210001,
.deposit-form--1290001 {
  flex-direction: column;

  .field-reference--400002,
  .field-stpAbonosAccountNumber--1210001,
  .field-reference--1290001 {
    input[type="text"] {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .wrapper {
      display: flex;
    }
    .input-root {
      flex: 1;
    }
    .button-default {
      margin-top: 20px;
      margin-bottom: 5px;
      color: color(secondary, main);

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        pointer-events: none;
        background: var(--select-icon-color);
      }
    }
    .button-default::after {
      display: none;
    }
  }
}

// Sr.Pago
.deposit-form--1010001 {
  > iframe + .deposit-form__circular-progress {
    position: absolute; // relative to .modal-paper
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
