.game-thumbnail {
  &__content,
  & > img {
    border-radius: 4px;
  }

  &__badges {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    & > * {
      left: 0;
      width: 100%;
      height: 100%;
      transform: initial;
      background-size: 35%;
      background-position: top left;
    }
  }
  &__title {
    color: color(primary, light);
    text-transform: uppercase;
  }
  &__button {
    border-radius: 4px;
    color: color(primary, light);

    &--real {
      background-color: color(accent, secondary);

      &::before {
        display: none;
      }
      &:hover {
        color: color(primary, light);
        border-color: #2a9c63;
        background-color: #2a9c63;
      }
    }
    &--favourite {
      min-width: inherit;
    }
  }
  &__jackpot-amount {
    font-family: 'Roboto', sans-serif;
    padding-left: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .featured-games-slider &{
    &__button--real {
      padding-top: 4px;

      &::before {
        display: none;
      }
    }
  }
  @include media-xs {
    .image-content {
      top: 6px;
      right: 6px;
      bottom: 6px;
      left: 6px;
    }
  }
}
