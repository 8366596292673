.game-info-page {
  width: 100%;
  height: 100%;

  &__inner {
    display: flex;
    width: 100%;
    min-height: 100%;
    background-color: var(--game-info-page-inner-background);
  }
  &__content {
    width: 100%;
    max-width: 400px;
    margin: auto;
    padding: 20px 15px 15px;
  }
  &__title {
    margin: 0 0 20px;
    font-size: 18px;
    text-align: center;
  }
  &__icon {
    display: block;
    margin-bottom: 10px;

    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__button {
    flex: 1;
    max-width: 50%;
    min-width: auto;
    margin: 0 4px;
    font-size: 14px;
    text-transform: capitalize;
    background: color(secondary, main);

  }
  &__error {
    margin: 15px 4px 0 4px;
  }
}

// [Playdoit]
.button-secondary.game-page__button {
  &_favourite_on,
  &_favourite_on:hover {
    background: color(primary, main);
    box-shadow: inset 0 0 0 1px color(primary, main);
  }
  &_favourite_off,
  &_favourite_off:hover {
    background: color(custom, main);
    box-shadow: inset 0 0 0 1px color(custom, main);
  }
}
