.select-locale {
  height: 40px;

  .select-icon {
    right: -18px;
  }
  &__item {
    flex: 0 0;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0;
    overflow: hidden;
    box-sizing: border-box;
    border-left: 5px solid transparent;

    &:focus {
      border-left: 5px solid transparent;
      background: none;
    }
    &:hover {
      border-left: 5px solid color(primary, main);
      background: none;
    }
  }
  &__flag {
    flex: 0 0;
    max-width: none;
    height: 40px;
    margin-right: 10px;
    border: 1px solid #eee;
  }
  &__lang-name {
    margin-right: 10px;
    font-size: 18px;
    text-transform: capitalize;
  }
  .select-selectMenu {
    padding: 0;

    .select-locale__item:hover {
      border-left-color: transparent;
    }
  }
  .field-select {
    & > div {
      &::before {
        display: none;
      }
      &:hover {
        &::before {
          display: none;
        }
      }
    }
  }
  .select-focused {
    border: none;

    &::before,
    &::after {
      display: none;
    }
  }
  ul {
    padding-bottom: 5px;
  }
  &__selector {
    .select-menuPaper {
      margin-left: -5px;
      min-width: auto !important;

      li {
        padding-left: 5px;

        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
  }
}
