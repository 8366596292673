.game-list {
  &--virtualGames &__title::before {
    @include svg-icon("custom");
    content: '\0051';
  }

  &__title {
    margin: 6px;
    padding: 5px;
    font-size: 16px;
    background-color: color(primary, dark);
    color: color(primary, light);

    .mobile &,
    .home-menu__wrapper--menuGameGroupsLive + .home-games &,
    .home-menu__wrapper--menuGameGroupsPromo + .home-games & {
      font-size: 18px;
      font-weight: 600;
      background-color: transparent;
      color: var(--button-default-color);
      &-link {
        font-weight: 400;
      }
    }
    .on-home & {
      padding: 0;
    }
    &-link {
      top: 0;
      color: var(--button-default-color);

      &::before {
        border-color: color(primary, dark) transparent transparent transparent;
      }
      &::after {
        background-color: color(primary, dark);
      }
      &:hover {
        color: color(primary, dark);
      }
      .tablet [data-url='/'] & {
        color: color(primary, light);

        &::after {
          background-color: color(primary, light);
        }
      }
    }
  }

  &__empty {
    margin: 6px;
    padding: 0;
    line-height: 34px;
  }
}
