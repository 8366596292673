.bia {
  &-frame {
    flex: 1;
    display: block;
    // width: 100vw; [Playdoit] p42K8XBb-PL added again and only coment max-width on bet on main branch
    // max-width: 1345px;
    margin: auto;
    border: none;
    transition: all 0.3s;

    &--loading {
      height: 0;
      visibility: hidden;
    }
  }
  &-progress {
    margin: 20px 0;
  }
  &-notification-wrapper {
    margin-top: 40px;

    @include media-sm {
      .gridItem-root {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }
  .prefill {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 60vw;
    min-height: 60vh;
    margin: auto;
    padding: 40px;
    background: var(--content-background);

    @include media-md {
      max-width: 100vw;
    }
    &__description {
      color: color(text, light);
    }
    .button-root {
      margin-top: 10px;
    }
  }
}
