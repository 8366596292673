.transactions {
  .empty {
    @media (min-width: 668px) {
      &::before {
        content: '—';
      }
    }
  }
  .custom-table--transactions {
    margin-bottom: 40px;

    @media (max-width: 667px) {
      margin-bottom: 0;
    }
    .custom-table {
      &-cell {
        > span {
          display: flex;
          align-items: center;
          justify-content: center;

          &::before {
            @include svg-icon;
            padding-right: 5px;
            font-size: 16px;
          }
          @media (min-width: 668px) {
            &.mobile-title {
              display: none;
            }
          }
        }
        .button-default {
          min-height: 0;
          padding: 0;
          text-transform: none;

          &::after {
            width: 100%;
            bottom: 0;
          }
          p {
            margin: 0;
          }
        }
        /* [Playdoit] */
        &--bet,
        &--win,
        &--amount {
          > span {
            font-family: 'Roboto';
          }
        }
        &--created-time {
          > span {
            &::before {
              content: '\0055';
            }
          }
          .button-default {
            .button-label {
              line-height: 1.4;
              text-decoration: underline;
              text-underline-position: under;
            }
            &::after {
              background: none;
            }
          }
          .button-label {
            &::before {
              @include svg-icon;
              padding-right: 5px;
              font-size: 16px;
            }
          }
          @media (min-width: 669px) and (max-width: 731px) {
            .button-default {
              max-width: 100px;
            }
          }
          @media (min-width: 960px) and (max-width: 1155px) {
            .button-default {
              max-width: 100px;
            }
          }
          @media (min-width: 1025px) {
            max-width: 150px;
          }
        }
        &--game {
          @media (min-width: 768px) {
            > span {
              &::before {
                content: '\0050';
              }
            }
            .fpLink {
              color: color(primary, dark);
            }
          }
          @at-root {
            .desktop {
              .game-page__icon,
              .image-img--default {
                display: none;
              }
            }
            .mobile,
            .tablet {
              @media (min-width: 668px) {
                .game-page__icon,
                .image-img--default {
                  display: none;
                }
              }
            }
          }
        }
        &--status {
          > span {
            text-transform: uppercase;
          }
          &.loss,
          &.rejected,
          &.cancelled,
          &.error,
          &.voided {
            > span {
              color: color(primary, main); // [Playdoit]
              &::before {
                content: '\0042';
              }
            }
          }
          &.win,
          &.ok,
          &.confirmed,
          &.checkedOut {
            > span {
              color: color(secondary, main);

              &::before {
                content: '\0074';
              }
            }
          }
          &.pending {
            > span {
              &::before {
                content: '\0072';
              }
            }
          }
          &.refunded {
            > span {
              &::before {
                content: '\0077';
                font-size: 14px;
              }
            }
          }
        }
        &--reference {
          .button-default {
            .button-label {
              line-height: 1.4;
              text-decoration: underline;
              text-underline-position: under;
            }
            &::after {
              background: none;
            }
          }
          @media (min-width: 668px) {
            .markdown-root {
              strong {
                font-weight: inherit;
              }
            }
          }
        }
        &--method {
          img {
            display: none;
          }
        }
        &--bonus {
          .markdown-root {
            p {
              margin: 0;

              + p {
                margin-top: 5px;
              }
            }
          }
        }
        &--name,
        &--type {
          > span {
            word-break: break-word;
          }
        }
      }
      @media (max-width: 667px) {
        &-row {
          display: block;
          position: relative;
          margin-bottom: 10px;
          padding: 10px 100px;
          background: var(--body-background);
        }
        &-cell {
          display: block;
          margin-bottom: 7px;
          padding: 0;
          border: 0;
          text-align: left;
        }
      }
      @include media-xs {
        &-row {
          padding: 10px 30px;
        }
      }
    }
    @media (max-width: 667px) {
      .mobile-title {
        font-weight: $font-weight-alt;

        &::after {
          content: ':';
          padding-right: 5px;
        }
      }
      .custom-table-row {
        &::after {
          content: '';
          display: block;
          clear: both;
        }
      }
      &.custom-table--transactions-game {
        .custom-table-cell {
          display: block;
          width: 60%;
          min-height: 23px;
          text-align: center;

          &--created-time {
            float: right;
            font-size: 12px;
          }
          &--game {
            float: left;
            width: 40%;

            .fpLink {
              display: flex;
              flex-direction: column-reverse;
              color: color(primary, dark);

              span {
                margin-bottom: 5px;
                font-weight: $font-weight-base;
              }
            }
            .game-page__icon {
              max-width: 126px;
              display: block;
            }
            .image-img {
              width: 100%;
              height: 100%;

              &--default {
                filter: grayscale(100%);
              }
            }
          }
          &--bet {
            float: right;

            > span {
              display: inline-flex;
            }
          }
          &--win {
            float: right;

            > span {
              display: inline-flex;
            }
            .mobile-title {
              &::after {
                content: ':';
                padding-right: 5px;
              }
            }
          }
          &--status {
            float: right;
            padding-left: 10px;

            span {
              padding-bottom: 5px;
              border-bottom: 1px dashed;
            }
          }
          &--reference {
            float: left;
            width: 40%;
            min-height: 0;
            margin-bottom: 0;
            clear: both;

            > span {
              display: inline-block;
            }
            strong {
              font-weight: $font-weight-base;
            }
          }
        }
      }
      .custom-table-cell {
        &--method {
          img {
            display: inline-block;
            max-width: 90px;
            max-height: 32px;
          }
          span span {
            display: none;
          }
        }
      }
      &.custom-table--transactions-deposit,
      &.custom-table--transactions-withdrawal {
        .custom-table-row {
          min-height: 110px;
        }
        .custom-table-cell {
          display: block;
          width: 50%;

          &--created-time {
            float: right;
            margin-top: 10px;

            .button-default {
              .button-label {
                &:after {
                  @include svg-icon;
                  content: '\0041';
                  position: absolute;
                  top: -15px;
                  right: -75px;
                  font-size: 20px;
                  color: color(secondary, main);

                  @include media-xs {
                    right: -25px;
                  }
                }
              }
            }
          }
          &--method {
            position: absolute;
            top: 55px;
            right: 55%;
            bottom: 10px;
            left: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: auto;
            border: 1px solid color(primary, dark);

            @include media-xs {
              left: 30px;
            }
          }
          &--amount {
            width: 45%;
            float: left;
            margin-top: 10px;
            text-align: center;

            span {
              display: inline-flex;
            }
          }
          &--bonus {
            float: right;
            clear: both;
            min-height: 10px;

            p::before {
              @include svg-icon;
              content: '\0056';
              margin-bottom: 2px;
              padding-right: 5px;
              padding-bottom: 1px;
              font-size: 18px;
              vertical-align: bottom;
              color: color(secondary, main);
            }
          }
          &--status {
            float: right;
            clear: both;
            padding-bottom: 10px;
            text-align: center;
            border-bottom: 1px dashed;

            &::before {
              @include svg-icon;
            }
            &.ok,
            &.pending,
            &.confirmed {
              color: color(secondary, main);

              > span:before {
                content: '\0043';
              }
            }
            &.cancelled,
            &.failure,
            &.rejected {
              color: color(primary, dark);

              > span:before {
                content: '\0042';
              }
            }
          }
          &--reference {
            display: none;
          }
        }
      }
      &.custom-table--transactions-withdrawal {
        .custom-table-cell {
          &--status {
            margin-top: 18px;
            margin-bottom: 0;
          }
          &--action {
            float: right;
            clear: both;
            margin-top: 10px;
            text-align: center;

            &.empty {
              margin-top: 0;
            }
          }
        }
      }
      &.custom-table--transactions-promo {
        .custom-table-row {
          padding-top: 20px;
        }
        .custom-table-cell {
          &--name {
            display: none;
          }
          &--amount {
            margin-right: 5px;

            > span::before {
              content: '\0056';
              margin-top: -2px;
              margin-left: 15px;
              padding-right: 3px;
              color: color(secondary, main);
              @media (max-width: 667px) {
                margin-left: 0;
              }
            }
          }
          &--type {
            float: left;
          }
        }
      }
    }

    &-withdrawal {
      .custom-table-cell {
        &--action {
          & > span {
            gap: 24px;

            body.dark-theme & .button-qrcode img {
              filter: invert(1);
            }
          }
        }
      }
    }
    &-promo {
      .custom-table-row {
        @media (max-width: 667px) {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
      }
    }
    &-promo {
      .custom-table-row {
        @media (max-width: 667px) {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
}

.transactions-bets {
  $color-muted: #777;

  margin: 12px 0 24px;
  padding: 30px;
  color: var(--body-text-dark-color);
  background: var(--transactions-bets-background);
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);

  @include media-sm {
    padding: 20px;
  }

  &__title {
    margin-bottom: 16px;
    color: $color-muted;
    font-size: 14px;
    line-height: inherit;
    text-transform: uppercase;
  }

  &__stats {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px -15px -10px;

    @include media-md {
      margin: 20px -10px -10px;
    }
  }

  &__stat {
    margin: 0 15px 10px;

    @include media-md {
      margin: 0 10px 10px;
    }

    &--bet,
    &--win {
      position: relative;
      padding-left: 24px;

      &::before {
        @include size(16px);
        position: absolute;
        top: 2px;
        left: 0;
        display: block;
        border-radius: 50%;
        content: "";
      }
    }
    &--bet {
      &::before {
        background: $color-muted;
      }
    }
    &--win {
      &::before {
        background: color(secondary, main); // [Playdoit]
      }
    }
    &--notice {
      flex: 1;
      color: #a09f9f;
      font-size: 12px;
      text-align: right;

      @include media-md {
        text-align: center;
      }
    }

    &-title {
      font-weight: $font-weight-alt;
      text-transform: uppercase;
    }
    &-amount {
      font-size: 12px;
    }
  }

  .linearProgress-root {
    .linearProgress-progressLine {
      height: 20px;

      .linearProgress-line {
        border-radius: 10px;
      }
      .linearProgress-progress {
        animation: none;
        transition: none;
      }
    }
  }
}

.transactions-form {
  @include media-sm {
    padding: 0 20px;
  }

  &__grid {
    width: auto;
    margin: 0 -20px;

    @include media-sm {
      margin: 0;
    }
  }

  &__grid-item {
    padding: 0 20px;

    @include media-sm {
      padding: 0;
    }
  }
  &--promo {
    &-loader {
      margin-bottom: 20px;
    }
  }
}

.transaction-details {
  &__table {
    display: table;
  }

  &__buttons {
    padding: 36px 0 30px;
  }
}

.transactions-paginator {
  margin-bottom: 40px;

  @media (max-width: 667px) {
    margin-bottom: 0;
  }
}

.post-login-content-wrap_transactions {
  @media (max-width: 667px) {
    background: none;

    .container {
      padding: 0;
    }
    h1 {
      margin-top: -15px;
      margin-bottom: 0;
      padding: 15px;
      background: var(--body-background);
    }
    .transactions-form {
      background: var(--body-background);
    }
    .transactions-result-loading,
    .transactions-result-message {
      margin-bottom: 20px;
      padding: 0 20px 20px;
      background: var(--body-background);
    }
  }
}
