.bonus-forfeit-modal {
  &__description {
    margin-bottom: 30px;
    text-align: center;

    @include media-sm {
      margin-bottom: 20px;
    }
  }

  // [Playdoit] Exchange positions of buttons
  &__buttons {
    flex-direction: row-reverse;
  }
}
