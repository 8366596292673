.mode-switcher {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 20px 5px 46px;

  .toggle-switcher {
    transform: scale(0.7);
  }
}
