.registration-modal {
  &:not(.registration-modal--error) {
    .modalContentWrapper {
      position: relative;
      padding-left: 300px;

      @include media-md {
        position: static;
        padding-left: 0;
      }
    }
  }
}
.registration-form {
  .fields-container {
    justify-content: space-between;
    margin-top: 20px;

    @media (min-width: 768px) {
      &__grid-root {
        margin: 0 -10px;
        padding: 0 10px;
      }
    }
  }
  &__successful {
    margin-top: 20px;
    text-align: center;

    .markdown-root {
      margin-bottom: 20px;
    }
  }
  &__gdpr-notification {
    font-size: 14px;
    text-align: justify;
    color: var(--modal-text-color);
  }
  &__buttons {
    display: flex;
    justify-content: center;
    margin: 20px auto 0;

    @include media-sm {
      margin-bottom: 20px;
    }
    button + button {
      margin-left: 30px;
    }
  }
  .fields-container__grid-root--ageChecked,
  .fields-container__grid-root--ageAndPolicyChecked {
    max-width: 100%;
    flex-basis: 100%;
    order: 10;
  }
  .fields-container__grid-root--privacyPolicyChecked,
  .fields-container__grid-root--receiveEmail,
  .fields-container__grid-root--receiveSms,
  .fields-container__grid-root--receiveEmailAndSms,
  .fields-container__grid-root--campaignsEnabled {
    max-width: 100%;
    flex-basis: 100%;
    order: 11;
  }
  .fields-container__grid-root--receiveEmail {
    label {
      align-items: flex-start;
    }
  }
  &:not(&--smartId):not(&--mobileId) {
    .fields-container__grid-root--nationalIdNumber:not(.fields-container__grid-root--selected-country-ROU) {
      display: none;
    }
  }
  &__logging-in {
    margin-top: 10px;
    text-align: center;

    &-text {
      margin-top: 10px;
    }
  }
  // [Playdoit]
  .fields-container__grid-root--currency,
  .fields-container__grid-root--agentCode {
    display: none !important;
  }
  .phone-number-wrapper {
    .select-select {
      width: 95px !important;
      padding: 0 12px;
    }
    /* [Playdoitmx] If used only one country on site */
    .phoneNumber_select--disabled {
      pointer-events: none;

      .select-icon {
        display: none;
      }
    }
  }
}
