/* Animation */

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

/*------------------------------------*\
          Style Guideline
\*------------------------------------*/

$font-fallback:
  // Safari for macOS and iOS (San Francisco)
  -apple-system,
    // Chrome < 56 for macOS (San Francisco)
  BlinkMacSystemFont,
    // Windows
  "Segoe UI",
    // Android
  "Roboto",
    // Basic web fallback
  "Helvetica Neue", Arial, sans-serif,
    // Emoji fonts
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
// [Playdoit] Changed default font
$font-primary: 'Titillium Web', sans-serif;
$font-weight-base: 400;
$font-weight-alt: 600;

/* color variable map */
$colors: (
  primary: (
    main: #ff0008,
    light: #fff, // ???
    dark: #808080, // ???
  ),
  secondary: (
    main: #3dc982,
    light: #fff350, // ???
    dark: #272829 // ???
  ),
  accent: (
    main: #ff0008,
    secondary: #3dc982
  ),
  custom: (
    main: #23589b,
    hover: #3b7ed4
  ),
  info: #ffcc00,
  error: #ed3030,
  success: #339999,
  warning: #e1a91e,
  expired: #d7b354,
  disable: (
    dark: #a09f9f,
  ),
  label: (
    filled: #666,
    unfilled: #666,
    disabled: #c3c3c3
  ),
  input: (
    text: #666,
    background: #fff,
    disabled: #c3c3c3,
    border: #808080,
    hint: #aaa
  ),
  select: (
    text: #666,
    background: #fff
  ),
  modal: (
    title: #333,
    text: #333,
    background: #fff
  ),
  footer: (
    text: #333,
    link: #666,
    link-active: #302f30,
    background: #e5e6e8
  ),
  turnover-progress: (
    text: #fff,
    progress: #3dc982, // [Playdoit]
    background: #acacac
  ),
  content: (
    title: #333,
    background: #fff
  ),
  text: (
    main: #666,
    light: #fff,
    dark: #333
  ),
  link: (
    default: #ff0008,
    hover: #ec3e45,
    active: #ff0008
  ),
  main-menu: (
    link: #333,
    link-active: #333, //[Playdoit] 7xExC7ND-PL
    background: #fff,
    background-active: #fff
  ),
  static-menu: (
    border: #e8e8e8,
    background: #f8f8f8
  ),
  loyalty-statuses: (
    bronze: #6398e6,
    silver: #f9813f,
    gold: #ffcd13,
    platinum: #339999,
  ),
  limit-amount: (
    progress-back: #ff0008,
    progress-path: #ff0008,
    switch-default: #ccc,
    background: #fff,
    color: #606060,
    date-background: #dedede
  ),
  background: (
    header: #ff0008,
    body: #fff,
    footer: #e5e6e8,
    aside-menu: #fff,
    home-menu: #fff,
    protection: #ff0008,
    modal: #fff,
    post-login-menu: #fff, // [Playdoit]
    post-login-content: transparent,
    static-menu: #fff,
    static-content: #fff,
    filter: #fff,
    game-window: #fff
  ),
  promotions-card: (
    title: #000,
    text: #fff,
    link: #3a405b,
  ),
  table: (
    even-background: #ebebeb,
  )
);

$preloading-width: 120px;
$preloading-height: 4px;
$preloading-time: 5s;

// heights
$header-collapsed-height: 50px;
$header-expanded-height: 60px;
$home-menu-collapsed-height: 70px;

// retrieve color from map. Example: 'color: color(primary, main);', 'background: color(background)'
@function color($color-name, $color-variant: null) {
  // color variant is optional
  @if ($color-variant != null) {
    @return map-get(map-get($colors, $color-name), $color-variant);
  }
  @else {
    @return map-get($colors, $color-name);
  }
}

/* Mixins */

@mixin svg-icon($name: 'Glyphter') {
  display: inline-block;
  @if $name == 'custom' {
    font-family: 'Glyphter-custom';
  } @else {
    font-family: 'Glyphter';
  }
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: initial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}
@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

$main-transition-time: .25s;

$size-xl: 1920px;
$size-lg: 1440px;
$size-md: 1024px;
$size-sm: 767px;
$size-xs: 480px;
$size-xxs: 320px; // [Playdoit]


@mixin media($max, $min: null) {
  @if ($min == null) {
    @media (max-width: $max) {
      @content;
    }
  } @else if($max == null) {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @media (max-width: $max) and (min-width: $min) {
      @content;
    }
  }
}
@mixin media-xl {
  @media (max-width: $size-xl) {
    @content;
  }
}
@mixin media-lg {
  @media (max-width: $size-lg) {
    @content;
  }
}
@mixin media-md {
  @media (max-width: $size-md) {
    @content;
  }
}
@mixin media-sm-landscape {
  @media (max-width: $size-sm) and (orientation: landscape) {
    @content;
  }
}
@mixin media-sm {
  @media (max-width: $size-sm) {
    @content;
  }
}
@mixin media-xs {
  @media (max-width: $size-xs) {
    @content;
  }
}
// [Playdoit]
@mixin media-xxs {
  @media (max-width: $size-xxs) {
    @content;
  }
}

// Size
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
@mixin customVh($vh: 100) {
  height: calc(var(--vh, 1vh) * #{$vh});
  will-change: height;
}

// Browsers check hacks
// Microsoft Edge
@mixin isMicrosoftEdge {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}

// Mozilla Firefox
@mixin isMozillaFirefox {
  @-moz-document url-prefix() {
    @content;
  }
}

// WebP background images with fallback
@mixin webp-bg-img($webp, $fallback) {
  background-image: url($webp);

  html.no-webp & {
    background-image: url($fallback);
  }
}

// Close button
@mixin close-button {
  @include size(32px);
  position: relative;
  min-width: 32px;
  min-height: 32px;
  padding: 0;
  flex: 0 0 auto;
  font-size: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  background: none;
  backface-visibility: hidden;

  &::before {
    @include svg-icon;
    content: '\004f';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--close-button-color);
    font-size: 10px;
    transition: all $main-transition-time;
  }

  &:hover {
    &::before {
      color: lighten(color(primary, dark), 20%);
      transform: translate(-50%, -50%) rotateZ(180deg);
    }
  }
}

:root {
  --text-color-main: #{color(text, main)};

  --body-background: #{color(background, body)};
  --body-text-dark-color: #{color(text, dark)};

  --content-title-color: #{color(content, title)};
  --content-background: #{color(content, background)};

  --home-menu-background: #{color(background, home-menu)};
  --home-menu-item-color: #808080;
  --home-menu-item-color-hover: #{color(primary, main)};
  --home-menu-icon-color: #808080;
  --home-menu-swiper-from: rgba(255, 255, 255, 1);
  --home-menu-swiper-to: rgba(255, 255, 255, 0);

  --header-background: #{color(background, header)};
  --header-color: #{color(primary, dark)};
  --header-border: 1px solid lighten(color(primary, dark), 60%);
  --header-box-shadow: none;

  --aside-menu-background: #{color(background, aside-menu)};
  --aside-menu-link-color: #{color(main-menu, link)};
  --aside-menu-border-color: #ccc;

  --close-button-color: #{color(primary, dark)};

  --button-secondary-color: #{color(primary, light)};
  --button-secondary-hover-color: #30ad6e;
  --button-default-color: #{color(primary, dark)};

  --post-login-content-background: #{color(background, post-login-content)};
  --post-login-menu-wrap-background: #{color(background, post-login-menu)};
  --post-login-settings-menu-background: #f8f8f8;
  --post-login-settings-menu-border-color: #e8e8e8;
  --post-login-settings-menu-expanded: #fff;
  --post-login-menu-link-color: #{color(text, main)};

  --footer-background: #{color(background, footer)};
  --footer-color: #{color(footer, text)};
  --footer-top-section-background: rgba(0, 0, 0, 0.10);
  --footer-link-hover-color: #{color(footer, link-active)};
  --footer-borders-color: #d0d0d0;

  --primary-light-color: #{color(primary, light)};

  --modal-background: #{color(background, modal)};
  --modal-title-color: #{color(modal, title)};
  --modal-text-color: #{color(modal, text)};

  --static-page-background: #{color(background, static-content)};
  --static-menu-background: #{color(background, static-menu)};
  --static-item-color: #{color(primary, dark)};
  --static-promotion-item-background: #e6e7e8;
  --static-promotion-item-text-color: #373435;
  --static-promotion-item-footer-border: #fff;
  --static-promotion-item-readmore-color: #96989a;

  --select-icon-color: #{color(select, text)};
  --select-menu-background: #{color(select, background)};
  --select-menu-item-color: #{color(select, text)};

  --input-color: #{color(input, text)};
  --input-hint: #{color(input, hint)};
  --input-border-color: #{color(input, border)};
  --input-label-unfilled-color: #{color(label, unfilled)};
  --input-label-filled-color: #{color(label, filled)};
  --input-label-disabled-color: #{color(label, disabled)};

  --form-secondary-background: #f2f2f2;

  --table-even-background: #{color(table, even-background)};
  --paginator-background: var(--body-background);

  --notification-background: #{color(primary, light)};
  --notification-color: #{color(text, main)};

  --limit-amount-color: #808080;
  --limit-amount-second-color: #{color(primary, dark)};
  --limit-amount-link-color: #{color(modal, text)};
  --limit-amount-background: #{color(limit-amount, background)};
  --limit-amount-progress-back: #{color(limit-amount, progress-back)};
  --limit-amount-progress-path: #{color(limit-amount, progress-path)};

  --limit-modal-color: #{color(modal, border)};

  --transactions-bets-background: #{color(primary, light)};

  --game-thumbnail-background: var(--body-background);
  --game-slider-nav-background: rgba(255, 255, 255, 0.7);
  --game-slider-nav-icon-color: #{color(secondary, main)};

  --success: #{color(success)};
  --warning: #{color(warning)};
  --error: #{color(error)};

  --game-info-page-inner-background: #fff;

  --winner-list-widget-box-shadow: inset 0 10px 10px -10px #eee;

  --balance-popover-background: #{color(primary, light)};
  --balance-popover-border-color: #{lighten(color(primary, dark), 50%)};
  --balance-popover-border: 1px solid var(--balance-popover-border-color);

  --protection-login-form-input-background: #{color(primary, light)};
  --protection-adornment-password-background:  #{color(primary, light)};

  --adornment-password-color: #{color(primary, dark)};

  --input-webkit-autofill-box-shadow: inset 0 0 0 100px #{color(input, background)};
  --input-webkit-text-fill-color: #{color(input, text)};

  --games-filter-background: #{color(background, filter)};
  --games-filter-text-color: #363636;

  --game-decorator-background: #000;

  --activity-form-cell-color: #000;
  --activity-form-post-login-title: #fff;

  --link-default-color: #{color(link, default)};
  --promotions-card-link-color: #{color(promotions-card, link)};
}
