.status-form {
  &__title {
    margin-top: 40px;
    margin-bottom: 0;
  }
  &__status {
    margin-top: 0;
    margin-bottom: 20px;
  }
  &__status-active {
    margin: 0 10px;
    font-weight: $font-weight-alt;
  }
  &__field {
    width: 100%;

    &--date {
      margin: 20px 0;
    }
    .formControlLabel-label {
      text-transform: uppercase;
    }
  }
  legend {
    margin-top: 20px;
    margin-bottom: 10px;
    color: var(--body-text-dark-color);
  }
  .buttons-grid {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 15px;
    .status-form__field {
      width: unset;
    }
    & .gridItem-root {
      display: inline-block;
      flex-basis: unset;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    .radioGroup-root {
      span {
        width: unset;
        background-color: transparent;
      }
    }
  }
}
