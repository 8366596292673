@import './GdWindow.custom';

.game-decorator {
  &__footer {
    .game-slider {
      &__nav-prev,
      &__nav-next {
        width: 35px;
        margin: 6px 0;
      }
    }
    &-toggle {
      top: -30px;
      font-size: 0;
      &::before {
        color: var(--link-default-color);
      }
    }
  }
}
