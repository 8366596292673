.game-thumbnail {
  margin: 0 6px 12px;
  padding: 6px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  // background-color: var(--game-thumbnail-background);

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 72%;
  }

  .image-content {
    position: absolute;
    top: 6px;
    right: 6px;
    bottom: 6px;
    left: 6px;

    &:hover {
      .game-thumbnail__content,
      .game-thumbnail__rtp {
        opacity: 1;
      }
      .game-thumbnail__badges,
      .game-thumbnail__last-results,
      .game-thumbnail__jackpot-amount,
      .game-thumbnail__jackpot-badge {
        opacity: 0;
      }
    }

    @include media-xs {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
  .remove_hover {
    &:hover {
      .game-thumbnail__content,
      .game-thumbnail__rtp {
        opacity: 0;
      }
      .game-thumbnail__badges,
      .game-thumbnail__last-results,
      .game-thumbnail__jackpot-amount,
      .game-thumbnail__jackpot-badge {
        opacity: 0;
      }
    }
  }
  &__placeholder {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  &--loaded {
    &::after,
    .game-thumbnail__placeholder:not(.game-thumbnail__placeholder_rendering) {
      display: none;
    }
  }
  &__badges {
    position: absolute;
    z-index: 4;
    top: -13px;
    right: -13px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    opacity: 1;
    transition: opacity $main-transition-time;
    pointer-events: none;

    &:empty {
      display: none;
    }
  }
  &__badge {
    flex: {
      grow: 0;
      shrink: 0;
    }
    background: {
      color: transparent;
      position: center;
      repeat: no-repeat;
      size: contain;
    }

    &--new {
      @include size(70px);
      transform: translate(7px, -7px);
      background-image: url('/cms/img/icons/new.svg');
    }
    &--free-spins {
      @include size(49px, 40px);
      background-image: url('/cms/img/icons/free-spins.svg');
    }
  }
  &__jackpot-amount {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 35px;
    padding-left: 28%;
    line-height: 35px;
    color: #fff;
    font-size: 14px;
    font-weight: $font-weight-alt;
    text-align: center;
    opacity: 1;
    background: rgba(0, 0, 0, 0.8);
    pointer-events: none;
    transition: opacity $main-transition-time;
    border: none;
  }
  &__jackpot-badge {
    @include size(53px, 40px);
    position: absolute;
    z-index: 4;
    bottom: 0;
    left: 3px;
    background: url('/cms/img/icons/jackpot.svg') transparent center/contain no-repeat;
  }
  /* Fix jumping game images (Safari) (1) */
  > img {
    flex-shrink: 0;
    height: 0; // 1
    width: 100%;
    padding-top: 72%; // 1
    visibility: hidden;
    object-fit: cover;
    object-position: center;

    &[src=""] {
      height: 0; // 1
    }
  }
  &--loaded img {
    height: 100%; // 1
    padding-top: initial; // 1
    visibility: initial;
  }
  &__title,
  &__rtp {
    .desktop .game-slider & {
      @include media-md {
        font-size: 1.75vw;
      }
    }
  }
  &__title {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    @include media-xs {
      opacity: 0;
    }
    .desktop & {
      @media (min-width: 768px) {
        @include truncate;
      }
    }
    @include media-md {
      font-size: 14px;
    }
    @include media-lg {
      font-size: 12px;
    }
  }
  &__buttons {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__button {
    position: relative;
    min-width: 60px;
    min-height: 30px;
    margin: 2px;
    padding: 4px 12px;
    color: #fff;
    align-items: center;

    &--real {
      @include media-xs {
        font-size: 12px;
        z-index: 100;
      }
      .desktop & {
      }
      // padding-top: 36px; [Playdoit]
      font-size: 16px;
      font-weight: $font-weight-base;
      color: #2f2f2f;
      background-color: color(primary, main);

      &::before {
        content: '';
        position: absolute;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 0 12px 24px;
        border-color: transparent transparent transparent #2f2f2f;
      }
      &:hover {
        background-color: darken(color(primary, main), 7%);
        color: #2f2f2f;
      }
      .is-logged-out & {
        @include media-lg {
          padding: 4px 12px;

          &::before {
            display: none;
          }
        }
      }
      [data-url="/group/all"] &,
      [data-url="/all-games"] &,
      .game-slider--game-page & {
        padding-top: 4px;

        &::before {
          display: none;
        }
      }
    }
    &--demo {
      font-size: 12px;
      border: 1px solid #fff;

      &:hover {
        color: #fff;
        background-color: rgba(255, 255, 255, .2);
      }
    }
    &--favourite {
      position: absolute;
      bottom: -3px;
      right: -3px;
      z-index: 3;
      min-width: 0;
      margin: 0;
      padding: 10px 12px;
      font-size: 0;
      line-height: 1;
      vertical-align: middle;
      border: 0;
      background: none;
      &::before {
        @include svg-icon;
        content: '\0057';
        font-size: 30px;
        color: white;
        @include media-xs {
          font-size: 20px;
          z-index: 100;
        }
      }
      .button-label {
        display: none;
      }
      &:hover {
        background: none;

        &::before {
          color: var(--error);
        }
      }
      .desktop & {
        @include media-sm {
          display: none;
        }
      }
    }
    &--favourite-on {
      &::before {
        content: '\0058';
      }
    }
  }
  &__button-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    padding: 0;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    pointer-events: none;
    @include media-xs {
      z-index: 0;
    }
    @media (pointer: coarse) {
      pointer-events: auto;
    }
    .desktop & {
      @include media-sm {
        pointer-events: auto;
      }
    }
  }
  &__content {
    padding: 8px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    opacity: 0;
    transition: $main-transition-time;
    background: rgba(0, 0, 0, 0.7);
    z-index: 0;
  }

  &__last-results {
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 6px 3px 33px;
    background: {
      color: rgba(255, 255, 255, .25);
      position: 0 50%;
      repeat: no-repeat;
      size: contain;
    };
    border-radius: 16px;
    transition: opacity $main-transition-time;

    @include media-sm {
      transform: translateX(-50%) scale(.9);
    }

    &--roulette {
      background-image: url('/cms/img/games/game-thumbnail/last-results-roulette.svg');
    }
    &--blackjack {
      background-image: url('/cms/img/games/game-thumbnail/last-results-blackjack.svg');
    }
    &--baccarat {
      background-image: url('/cms/img/games/game-thumbnail/last-results-baccarat.svg');
    }
  }
  &__last-results-item {
    @include size(20px);
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    font-weight: $font-weight-alt;
    line-height: 20px;
    text-align: center;

    &:not(:last-of-type) {
      margin-right: 3px;
    }

    &:first-of-type {
      @include size(24px);
      font-size: 12px;
      line-height: 24px;
    }

    &--red {
      background: #ff4747;
    }
    &--black {
      background: #000;
    }
    &--green {
      background: #71ac31;
    }
  }
  &__dealer {
    max-width: 100%;
  }
  .featured-games-slider & {
    .game-thumbnail__badges,
    .game-thumbnail__jackpot-amount,
    .game-thumbnail__jackpot-badge {
      display: none;
    }

    &__title,
    &__rtp {
      font-size: 20px;
    }
    &__button--real {
      // padding-top: 36px; [Playdoit]

      &::before {
        display: block;
      }
    }
  }

  &__liveCasino {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
    font-size: 14px;

    &__top,
    &__bottom {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &--item {
      width: max-content;
      display: flex;
      font-weight: 700;
      color: white;
      align-items: center;
      position: relative;
      padding: 5px;
      gap: 4px;
      border-radius: 4px;

      &--dealerName {
        background-color: rgba(0, 0, 0, 0.6);
        display: inline-flex;
        font-size: 12px;
      }

      &--dealerName + &--result {
        padding-top: 0;
      }

      &--roulette {
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        height: 40px;
        flex-wrap: wrap;
        overflow: hidden;

        span {
          min-width: 34px;
          height: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          padding: 5px;
          border-radius: 4px;
        }

        &--black {
          border: 2px solid #333333;
          background: black;
        }

        &--red {
          background: red;
        }

        &--green {
          background: green;
        }

        &--undefined {
          display: none !important;
        }

        @include media-xs {
          gap: 5px;
          margin-right: 0;
          height: 40px;
        }
      }

      &--blackjack {
        background-color: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;
        &--item {
          opacity: 0.5;

          &--active {
            opacity: 1;
          }
          &:before {
            display: inline-block;
            font-family: Glyphter;
            content: "E";
          }
        }
      }

      &--person {
        background-color: rgba(0, 0, 0, 0.6);
        &:before {
          display: inline-block;
          @include svg-icon("custom");
          content: '\0028';
        }
      }

      &--bet {
        background-color: rgba(0, 0, 0, 0.6);
        &:before {
          display: inline-block;
          @include svg-icon("custom");
          content: '\0027';
        }
      }

      &--availableSeats {
        background: #3DC982;
        &:before {
          display: inline-block;
          font-family: Glyphter;
          font-size: 10px;
        }
      }
    }
  }
}

.game-slider {
  .game-thumbnail {
    @include media-xs {
      &__liveCasino {
        &--item {
          gap: 2px;
          font-size: 9px;
          padding: 2px;

          &--roulette {
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            height: 26px;
            flex-wrap: wrap;
            overflow: hidden;

            span {
              min-width: 22px;
              height: 22px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 10px;
              padding: initial;
              border-radius: 4px;

              @media screen and (max-width: 376px) {
                min-width: 20px;
              }
            }
          }
        }
      }
    }
  }
  .double-roulette {
    span:nth-child(2n) {
      margin-right: 24px;
    }

    @include media-xs {
      gap: 4px 1px;
      margin-left: 2%;

      span:nth-child(2n) {
        margin-right: 5px;
      }
    }
  }
}

.double-roulette {
  gap: 4px 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;

  .game-thumbnail__liveCasino--item--roulette--red {
    background: red;
    border: none;
  }

  .game-thumbnail__liveCasino--item--roulette--green {
    background: green;
    border: none;
  }

  span:nth-child(2n) {
    margin-right: 20px;
  }

  @include media-xs {
    margin-left: 3%;

    span:nth-child(2n) {
      margin-right: 5px;
    }
  }
}
