@mixin white-header {
  background: color(accent, main);

  & .button-default {
    color: color(primary, light);

    &:hover .button-label span::after {
      background: color(accent, main);
    }
    &.header__resume .button-label span::after {
      background: color(accent, main);

      @media (max-width: 360px) {
        background: none;
      }
    }
  }
}

.header {
  background: color(background, header);

  .button-root {
    background: none;
  }

  & &__verification {

    .button-label::before {
      @include svg-icon("custom");
      content: '\0057';
      color: color(primary, light);
    }
  }

  & &__betting {
    margin-right: auto;

    .button-label::before {
      @include svg-icon("custom");
      content: '\004a';
      color: color(white);
    }
  }
  & &__registration {
    .button-label::before {
      @include svg-icon("custom");
      content: '\0057';
    }
  }
  & &__tournaments {
    margin-right: 0;
    .button-label::before {
      @include svg-icon("custom");
      content: '\0054';
    }
  }
  &--scrolled {
    @include white-header;
    border-bottom: none;

    .button-default {
      color: color(primary, light);
    }
  }
  & &__balance {
    margin-right: 10px;
    border-right: none;

    @include media-md {
      color: color(primary, light);
    }
  }

  @include media-sm {
    & &__deposit,
    & &__login {
      font-size: 0;
    }
  }

  #root:not([data-static-url]):not([data-url='/']):not([data-url='/live']):not([data-url^='/group/']) & {
    @include white-header;
  }
}
