.modal-root .modal-contentRoot {
  .incode-verification-form {
    margin: 0 -50px;

    @include media-sm {
      margin: 0 -20px;
    }
  }
}
.modal-root,
.popup-decorator {
  overflow: visible;
  border-radius: 0;

  &.is-submodal {
    .modal-paper {
      width: 420px;
      margin: 16px;

      .modalContentWrapper {
        .modal-titleRoot {
          padding-top: 25px;
        }

        .modal-contentRoot {
          padding-right: 25px;
          padding-left: 25px;

          .markdown-root {
            text-align: center;
          }
        }
      }
    }
  }

  .modal-buttonRoot {
    @include close-button;
  }
  .modal-rootButtons {
    position: absolute;
    right: 14px; // aeVpWKTc-PL modal-root Buttons moved a little more to right
    top: 8px;
    z-index: 1;
  }
  .modal-rootButton {
    > .button-default {
      min-height: 32px;
      padding: 4px 8px;
      color: color(secondary, main);
      font-size: 16px;
      text-decoration: none;

      &:after {
        display: none;
      }
      > .button-label {
        white-space: nowrap;

        &::before {
          content: '';
          position: absolute;
          bottom: -3px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: color(secondary, main);
          transition: all $main-transition-time;
        }
        &:hover::before {
          opacity: 0;
        }
      }
    }
  }
  .modal-paper {
    width: 720px;
    max-width: 100%;
    border-radius: 0;
    background: var(--modal-background);

    .modalContentWrapper {
      display: flex;
      flex-direction: column;
      overflow-y: auto; // [FIN-21054] - wrong style in FireFox

      .modal-titleRoot {
        padding: 40px 20px 20px;
        background: var(--modal-background);

        h2 {
          text-align: center;
          text-transform: none;
          font-size: 30px;
          color: var(--modal-title-color);
        }
        & + .modal-contentRoot {
          padding-top: 0;

          h1 {
            margin-top: 10px;
          }
        }
      }

      .modal-contentRoot,
      .popup-decorator__content {
        color: var(--modal-text-color);
        border: 0;
        border-radius: 0;
      }
      .modal-contentRoot {
        padding-bottom: 0;
        overscroll-behavior: none;
      }
      .modal-contentRoot > div:last-child {
        padding-bottom: 30px;

        @include media-sm {
          padding-bottom: 15px;
        }
      }

      .modal-contentRoot > div:not(.notification-root):not(.grid-root):not(.deposit-form):not(.withdrawal-form):not(.qrcode-form):not(.resume-form):not(.verification-form):not(.game-search) {
        display: block;
        height: 100%;

        @include isMozillaFirefox {
          height: auto;
        }

        @include isMicrosoftEdge {
          height: auto;
        }
      }
      .field-root {
        padding-right: 0;
      }
    }
  }
  .content__wrapper {
    width: 100%;

    .group-root {
      width: 100%;
    }
  }
}

.page-modal--id-forfeit-bonus {
  h2 {
    display: none;
  }
}

.registration-modal:not(.registration-modal--error),
.page-modal--id-terms-conditions,
.page-modal--id-privacy-policy,
.page-modal--id-responsible-gambling,
.page-modal--id-bonus-terms {
  .modal-paper {
    width: 1168px;
  }
  .underlined-title {
    display: none;
  }
}
.modal__gambling-message {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: auto !important;
  margin: 20px 0;
  padding-bottom: 0 !important; // override: .modal-root .modal-paper .modalContentWrapper .modal-contentRoot > div:last-child `padding-bottom`
  text-align: center;

  @include media-sm {
    text-align: left;
  }

  img {
    @include size(32px);
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 8px;
    filter: brightness(0) grayscale(100%);
  }

  p {
    margin: 0;
    font-size: 14px;
    color: color(text, light);
  }
}
.confirmation-modal__buttons {
  margin-top: 20px;
  justify-content: center;

  .gridItem-root {
    max-width: initial;
    flex-basis: auto;
  }
}

/* QRcode modal */
.qrcode-modal {
  .modal-paper {
    width: 440px;
  }

  .qrcode-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__code {
      width: 250px;
      height: 250px;
      border: 1px solid black;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .button-root {
    p {
      margin: 0;
    }

    strong {
      font-weight: $font-weight-base;
    }
  }
}


/* Cancel modal */
.cancel-confirmation-modal {
  text-align: center;
  .modal-paper {
    width: 420px;
  }
}

/* Reality check modal */

.reality-check-modal {
  &__descriptor {
    &-addon {
      display: inline;
      padding-right: 5px;

      p {
        display: inline;
        margin: 0;
      }
      & + .button-default {
        min-height: 0;
        padding: 0;

        &::after {
          bottom: 0;
          width: 100%;
        }
      }
    }
  }
  &__buttons {
    text-align: center;

    button,
    button + button {
      margin: 0 7.5px;
    }
    @include media-xs {
      button + button {
        margin-top: 15px;
      }
    }
    .button-secondary {
      @include button-secondary(color(primary, dark), color(primary, dark));
    }
  }
  // [Playdoit]
  .button-secondary {
    @include button-secondary;
  }
}

/* FreeSpins Finished modal */

.free-spins-finished-modal {
  &__buttons {
    text-align: center;

    button,
    button + button {
      margin: 0 7.5px;
    }
    @include media-xs {
      button + button {
        margin-top: 15px;
      }
    }
  }
}

.campaigns__modal{
  .markdown-root{
    text-align: center;
  }
}

.campaigns__modal{
  .markdown-root{
    text-align: center;
  }
}

@include media-sm {
  .modal-root {
    .modal-contentRoot {
      display: flex;
      flex-direction: column;
      padding: 20px 20px 15px;
    }
    .modal-paper {
      width: 100%;
      height: 100%;
      max-height: 100%;
      margin: 0;
      justify-content: center;
    }
  }
}
