.login {
  margin-bottom: 30px;

  .container {
    max-width: 800px;
    margin-top: 15px;
    padding: 30px;
    background: var(--content-background);
  }
  &-form {
    .group-root--buttons {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-xs {
        justify-content: left;
      }
    }
    &__resume-play {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: calc(100% + 60px);
      margin: 30px -30px -30px;
      padding: 20px;
      text-align: center;
      background: var(--form-secondary-background);

      .applied-country--gbr & {
        margin-bottom: 0;
      }

      .login-modal & {
        max-width: calc(100% + 100px);
        margin: 30px -50px -30px;

        .applied-country--gbr & {
          margin-bottom: 0;
        }

        @include media-sm {
          margin: 20px -20px 0;
        }
        @include media-xs {
          margin: 20px -10px 0;
        }
      }
      .markdown-root {
        margin-bottom: 15px;

        p {
          margin: 0;
        }
      }
      .login__resume {
        padding: 10px 30px;
        color: color(primary, dark);
        font-weight: $font-weight-base;
        text-transform: none;

        &:hover {
          color: color(primary, light);
        }
      }
    }
  }
  &__button {
    @include media-xs {
      min-width: auto;
    }
  }
  &__forgot-password {
    position: absolute;
    right: 0;
    margin-left: 15px;
    color: var(--modal-text-color);
  }
}
