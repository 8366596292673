.activity-form {
  &__cell-title {
    display: none;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: var(--activity-form-cell-color);
  }
  .custom-table-cell--status--login {
    color: var(--success)
  }
  .custom-table-cell--status--logout {
    color: var(--error)
  }
  @media (max-width: 668px) {
    padding: 0;

    .custom-table {
      display: block;
    }
    .custom-table-cell {
      border: none;
    }
    &__item,
    &__count,
    & > .grid-root:first-child {
      padding: 0 20px;
      background: var(--content-background);
    }
    &__item {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      border-bottom: 1px solid color(primary, dark);

      .custom-table-cell {
        flex: 1;
        white-space: nowrap;
        padding: 15px;

        &:first-child {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
    &__cell-title {
      display: block;
    }
    &__item {
      margin: 10px 0;
    }
  }
}
.post-login-content-wrap--activity {
  @media (max-width: 668px) {
    background: none;

    .post-login__title {
      background: var(--activity-form-post-login-title);
      margin: 0;
      padding: 20px 0;
    }
  }
}
