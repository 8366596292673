.mobile {
  .home-menu {
    .menu-content {
      padding: 0;
    }
    .menu-root {
      margin-right: 20px;
    }
    &__wrapper--customItems {
      .menu-root {
        margin: 0;
      }
    }
  }
}

.home-menu {
  a,
  span {
    padding: 10px 0;
    color: var(--home-menu-item-color);
    font-size: 16px;

    @include media-sm {
      padding: 0;
      font-size: 0;
      & > span {
        display: none;
      }
    }

    &:not(.chat):hover:before,
    &:not(.chat):hover {
      color: var(--home-menu-item-color-hover);
    }
    &::before {
      @include svg-icon("custom");
      display: block;
      margin-bottom: 4px;
      color: var(--home-menu-item-color);
      font-size: 21px;
    }
    &.active,
    &.active::before {
      color: var(--home-menu-item-color-hover);
    }
    $iconList: (
      '^="/"': '\004a',
      '*="/casino"': '\0058',
      '*="/popular"': '\002e',
      '*="/tournaments"': '\0054',
      '*="/promo"': '\0064',
      '="/promociones"': '\0044',
      '*="/promotions"': '\0044',
      '*="/slots"': '\004b',
      '*="/crashGames"': '\005b',
      '*="/jackpot"': '\004c',
      '*="/table"': '\004d',
      '*="/liveRoulette"': '\004d',
      '*="/bingo"': '\0033',
      '*="/all-games"': '\0037',
      '*="/freeSpins"': '\0075',
      '*="/favourite"':'\0065',
      '*="/historial#page=betHistory"': '\0046',
      '*="betinaction-virtual"': '\0051',
      '*="/livePlaydoit"': '\0022',
      '*="/livePopular"': '\0031',
      '*="/liveBaccarat"': '\004f',
      '*="/liveBlackjack"': '\004e',
      '*="/liveGameShows"': '\002c',
      '*="/livePoker"': '\0032',
      '$="/live"': '\004e',
      '$="/juegos-premios"': '\0044',
      '*="/premiosMisterioso"': '\002a',
      '*="/cashBlast"': '\0029',
      '*="/lluviaDePremios"': '\002b',
      '*="/happyHour"': '\002f',
      '*="/goldenAwards"': '\005d',
      '*="/promoDeTemporada"': '\005c',
      '*="/evolutionPrizes"': '\002d',
      '*="/aplicacion-movil"': '\0035',
      '*="/new-games"': '\005a',
      '*="?modal=login"': '\0043',
      '$="?modal=deposit"': '\0047',
    );
    @each $url, $icon in $iconList {
      &[href#{$url}] {
        &::before {
          content: $icon;
        }
      }
    }

    &.chat {
      &::before {
        content: '\0045';
      }
      &--loading {
        opacity: .3;
        pointer-events: none;
      }
    }
  }
  &.swiper-root {
    .swiper-prev,
    .swiper-next {
      &.is-disabled {
        display: none;
      }
    }
  }
}

.home-menu__wrapper--menuGameGroups,
.home-menu__wrapper--menuGameGroupsLive,
.home-menu__wrapper--menuGameGroupsPromo {
  .home-menu {
    a,
    span {
      @include media-sm {
        padding: 10px 0;
        font-size: 14px;
        & > span {
          display: block;
        }
      }
    }
  }
  .swiper-wrapper:first-of-type{
    min-width: 40%; // PL-3JnKhkZh fix to live menu on tablet devices
  }
}

/** Put menu to the bottom of page on small devices */
.main-decorator {
  .home-menu__wrapper {
    &--menuGameGroups {
      .menu-content {
        justify-content: center;
      }
    }
  }
  @include media-md {
    display: flex;
    flex-direction: column;

    .home-menu__wrapper {
      &--customItems {
        order: 1;
        bottom: -1px;
        @media all and (display-mode: standalone) {
          padding: 15px 0;
        }
        & .settings-menu__item-override {
          padding: 10px 15px;
        }
      }
      &--menuGameGroups {
        order: 0;
        top: 50px;

        .menu-content {
          justify-content: space-between;
        }
      }
    }
  }
}
