.game-list--all {
  .game-list__title {
    display: none;
  }
  .game-thumbnail {
    @media (min-width: 768px) {
      width: calc(33.33333% - 12px);
    }
    @media (min-width: 1025px) {
      width: calc(25% - 12px);
    }
  }
}
.games-filter {
  margin-bottom: 0;
  margin-left: 20px;
  padding: 0 10px; //GYPGserI-PL removede the padding on search input
  text-align: left;
  background: var(--games-filter-background);

  @include media-md {
    top: 49px;
  }
  @include media-sm {
    max-height: 100px;
    overflow: hidden;
    margin-left: 0;

    &--show {
      max-height: none;
    }
  }
  &__group + &__group {
    margin-top: 15px;
  }
  &__group-title {
    margin-bottom: 8px;
    padding-bottom: 9px;
    border-bottom: 4px solid color(primary, main);
    font-size: 12px;
    font-weight: $font-weight-base;
    color: var(--games-filter-text-color);
    text-transform: uppercase;
  }
  .radio-list {
    .formControlLabel-root {
      display: inline-flex;
      margin-right: 10px;
      float: none;
      clear: none;
    }
    .formControlLabel-label {
      padding-left: 5px;
    }
  }
  .range-range {
    background: color(primary, main);
    background-clip: content-box;
  }
  &__name {
    width: 100%;
    margin-bottom: 15px; // [Playdoit]
  }
}
.games-filter__group {
  .field-helper {
    display: none;
  }
  .field-checkbox {
    margin: 0;
    max-width: 100%;
  }
  .field-checkbox .checkbox-default + span,
  .field-checkbox .checkbox-checked + span {
    color: var(--games-filter-text-color);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .field-checkbox .checkbox-checked + span {
    color: var(--games-filter-text-checked-color);
  }

  .checkbox-default {
    display: none;

    & + span {
      margin-right: 6px;
      padding: 6px 8px;
      font-size: 12px;

      &:hover {
        box-shadow: inset 0 0 0 1px color(primary, main);
      }
    }
    &.checkbox-checked + span {
      background-color: color(primary, main);
    }
  }

  &--sort {
    .radio-list {
      .formControlLabel-label {
        white-space: nowrap;
      }
    }
  }

  @include media-sm {
    &--hide {
      max-height: 100px;
      overflow: hidden;
    }
  }
}
.games-filter__footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  .button-default {
    padding-right: 0;
    padding-left: 0;

    &::after {
      width: 100%;
    }
  }
}
.games-filter__footer-number {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-weight: $font-weight-base;
}
// [Playdoit]
.input-underline {
  &::after {
    background-color: var(--link-default-color);
  }
}
@import '../GameFeaturesFilter/GameFeaturesFilter';
