.reality-check-modal {
  &__descriptor {
    &-addon {
      display: inline;
      padding-right: 5px;

      p {
        display: inline;
        margin: 0;
      }
      & + .button-default {
        min-height: 0;
        padding: 0;

        &::after {
          bottom: 0;
          width: 100%;
        }
      }
    }
  }
  &__buttons {
    text-align: center;

    button,
    button + button {
      margin: 0 7.5px;
    }
    @include media-xs {
      button + button {
        margin-top: 15px;
      }
    }
    .button-secondary {
      @include button-secondary(color(primary, dark), color(primary, dark));
    }
  }
}
