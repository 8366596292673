/* fixed the problem with the height of methods in the modal deposit */
.deposit-modal {
  &--frame {
    .modalContentWrapper {
      max-height: 90vh;

      @include media-sm {
        height: 100vh;
        max-height: none;
      }
    }
    .modal-contentRoot {
      height: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &--shuftipro {
    .modal-paper {
      width: 100%;
      max-width: 870px;
    }
  }
}
