.post-login-menu {
  border: 1px solid var(--post-login-settings-menu-border-color);
  .settings-menu__item-override {
    a {
      padding-right: 15px;
      padding-left: 75px;
      &:not([href*="questionnaire"])::before {
        @include svg-icon("custom");
      }
      &.active,
      &:hover {
        font-weight: 400;
      }
      &[href*="profile"] {
        &::before {
          content: '\0057';
        }
      }
      &[href*="validation"] {
        &::before {
          content: '\0069';
        }
      }
      &[href*="documents"] {
        &::before {
          content: '\0067';
        }
      }
      &[href*="change-password"] {
        &::before {
          content: '\0068';
        }
      }
      &[href="/my/profile-account"] {
        &::before {
          content: '\0066';
        }
      }
      &[href*="activity"] {
        &::before {
          content: '\006a';
        }
      }
      &[href="/my/profile-close"],
      &[href*="self-exclusion"] {
        &::before {
          content: '\0072';
        }
      }
      &[href*="promotions"] {
        &::before {
          content: '\006b';
        }
      }
      &[href*="promociones"]  {
        &::before {
          content: '\0036';
        }
      }
      &[href*="loyalty"] {
        &::before {
          content: '\0054';
        }
      }
      &[href="/my/limits/time-out"] {
        &::before {
          content: '\006d';
        }
      }
      &[href="/my/limits/time"] {
        &::before {
          content: '\006e';
        }
      }
      &[href*="gaming"] {
        &::before {
          content: '\0071';
        }
      }
      &[href*="responsible-gambling"] {
        &::before {
          content: '\006c';
        }
      }
      &[href*="game"] {
        &::before {
          content: '\0071';
        }
      }
      &[href*="deposit"],
      &[href*="payments"] {
        &::before {
          content: '\006f';
        }
      }
      &[href*="withdrawal"] {
        &::before {
          content: '\0070';
        }
      }
    }
  }
}
