.game-slider {
  &__game {
    width: calc((100vw - 36px) / 3);
    margin-right: 12px;

    .desktop & {
      width: calc((100vw - 78px) / 5);
      max-width: 240px;
    }
  }
  &__slide {
    padding: 0;
  }
  .game-thumbnail {
    .image-content {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
  &__nav-prev,
  &__nav-next {
    opacity: .8;

    &::before {
      color: var(--link-default-color);
    }
  }
  &--game-page {
    .game-thumbnail--new
    .game-thumbnail--jackpot {
      &::before {
        top: 6px;
        right: 6px;
      }
    }
  }
}
