.aside-menu {
  &__greeting {
    grid-area: locale;
    justify-self: start;
    margin: 28px 17px;
  }

  &__close {
    margin: 18px;
  }

  &__login,
  &__deposit {
    display: block;
    margin: 0 auto;
    width: 75%;
    background-color: color(accent, secondary);

    &:hover {
      background-color: #229058;
    }
  }
  &__logout {
    margin: 0 auto;
    padding-left: 0;

    & > button {
      @include button-primary;
      box-shadow: none;
      &:hover {
        box-shadow: none;
        background-color: darken(color(primary, main), 8%) !important;
      }
    }

    .mobile & {
      padding-bottom: 100px;
    }
  }
  $iconList: (
    '*="/"': '\004a',
    '*="/casino"': '\0058',
    '*="/tournaments"': '\0054',
    '*="/favourite"': '\0038', // EtJHJbRH-PL Correcting favoritos icon
    '*="/en-vivo"': '\0050',
    '*="/popular"': '\002e',
    '*="/historia"': '\0046',
    '*="/promo"': '\0064',
    '*="games/betinaction-virtual-football-league"': '\0051',
    '*="reglasDeJuego"': '\0073',
    '$="resultados"': '\0074',
    '*="/bingo"': '\0033',
    '*="slots"': '\004b',
    '*="crashGames"': '\005b',
    '*="promotions"': '\0044',
    '*="jackpot"': '\004c',
    '*="table"': '\004d',
    '*="/live"': '\004e',
    '$="promo"': '\0064',
    '*="/new-games"': '\005a',
  );
  [class*='menuAsideGameGroups'] {
    .settings-menu__item-override {
      a {
        margin: 14px 0 0 33px;
        &::before {
          top: 3px;
          content: '\0041';
          @include svg-icon("custom");
        }
        @each $url, $icon in $iconList {
          &[href#{$url}] {
            &::before {
              content: $icon;
            }
          }
        }
      }
    }
  }

  [class*='menuAsideBettingGames'] {
    .settings-menu__item-override {
      a {
        margin: 14px 0 0 33px;
        &::before {
          @include svg-icon("custom");
          position: absolute;
          top: 3px;
          left: -13px;
          font-size: 20px;
          color: var(--aside-menu-link-color);
        }
        &.active {
          &::after {
            display: none;
          }
          &::before {
            color: var(--link-default-color);
          }
        }
        @each $url, $icon in $iconList {
          &[href#{$url}] {
            &::before {
              content: $icon;
            }
          }
        }
      }
    }
  }
  .settings-menu {
    &__item-override {
      &--survivor {
        padding: 14px 30px 0px 46px;
        a {
          margin: 0;
          padding: 0;
          position: relative;
          &:after {
            content: "";
            background: url('/cms/img/survivor.png') center/contain no-repeat;
            display: inline-block;
            width: 45px;
            height: 27px;
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }
}
