@mixin button-secondary-box-shadow($color: color(secondary, main)) {
  box-shadow: inset 0 0 0 1px $color;
}
@mixin button-accent($background: color(secondary, main), $color: color(primary, light)) {
  color: $color;
  background-color: $background;

  &:hover {
      color: $color;
      background-color: darken($background, 8%);
  }
}
@mixin button-primary($background: color(primary, main), $color: color(primary, light)) {
  color: $color;
  background-color: $background;

  &:hover {
      color: $color;
      background-color: darken($background, 8%);
  }
}
@mixin button-secondary($color: var(--button-secondary-color), $border: var(--button-secondary-color)) {
  color: $color;
  // [Playdoit]
  background: color(secondary, main);
  text-transform: uppercase;

  &:hover {
    :not(.mobile) & {
      color: $color;
      background-color: var(--button-secondary-hover-color);
    }
  }
}
@mixin button-default($color: var(--button-default-color), $background: color(primary, dark)) {
  min-width: auto;
  position: relative;
  font-size: 14px;
  color: $color;
  background: none;

  &::after {
    content: '';
    display: block;
    width: calc(100% - 36px);
    height: 1px;
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $background;
    transition: $main-transition-time;
  }
  &:hover {
    :not(.mobile) & {
      background-color: transparent;

      &::after {
        opacity: 0;
      }
    }
  }
}

.button-root {
  min-width: 156px;
  min-height: 42px;
  padding: 10px 18px;
  border-radius: 0;
  font-size: 16px;
  font-family: $font-primary;
  color: var(--body-text-dark-color);
  text-align: center;
  text-transform: unset;
  line-height: 16px;

  &.button-loading {
    color: transparent;
  }
}
.button-accent {
  text-transform: uppercase; // [Playdoit] L1EUxSJC-PL buttons text uppercase
  border-radius: 4px; // [Playdoit]
  @include button-accent;
}
.button-primary {
  text-transform: uppercase; // [Playdoit] L1EUxSJC-PL buttons text uppercase
  border-radius: 4px;
  @include button-primary;
}
.button-secondary {
  transition: color $main-transition-time, background-color $main-transition-time;
  border-radius: 4px;
  @include button-secondary;
}
.button-default {
  @include button-default;
}
.button-qrcode {
  padding: 0;
  min-width: 42px;
  height: 42px;

  span {
    width: inherit;
    height: inherit;
  }

  img {
    width: inherit;
    height: inherit;
  }

  & + .button-root {
    min-width: unset;
  }
}
.button-disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.group-root--buttons {
  margin-top: 15px;
  margin-bottom: 15px;

  button + button {
    margin-left: 15px;
  }
}
.group-root--password-buttons {
  @include media-sm {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    .button-root {
      margin: 0;
    }
  }
}
