.post-login-content-wrap--limits {
  display: flex;
  flex-direction: column;
  padding-top: 0;
  background-color: transparent;

  &-time-out,
  &-responsible-gambling,
  &-self-exclusion {
    background: var(--post-login-content-background);
  }
  .static-content {
    padding: 10px 30px;
  }
  .button-secondary {
    @include button-secondary(var(--body-text-dark-color), var(--body-text-dark-color));
    margin: 30px 0 15px;
  }
}
.post-login-content-wrap--limits-responsible-gambling .limits__title {
  display: none;
}
.limits {
  &__title {
    margin: 0;
    padding: 40px 10px 10px;
    background-color: var(--post-login-content-background);
    text-align: center;
  }
  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0;
    padding: 0;
  }
  &__item {
    flex-basis: 0;

    &:empty {
      display: none;
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
  }
  &__item--last {
    flex-grow: 1;
    background-color: var(--post-login-content-background);
  }
}
.limit-form {
  margin-bottom: 20px;
  padding: 10px 30px;
  background-color: var(--post-login-content-background);

  .deposit-form &,
  .cumulative-deposit-limits-modal & {
    margin-bottom: 0;
    padding: 10px 0;
  }
  .deposit-form &,
  .cumulative-deposit-limits-modal &,
  .game-decorator__error-content--limits & {
    .button-secondary {
      @include button-secondary(var(--body-text-dark-color), var(--body-text-dark-color));
      margin-top: 20px;
    }
  }
  &--space {
    margin-bottom: 0;
  }
  &__title {
    margin: 15px 0;
    text-align: center;
  }
  &__item {
    margin: 35px 0 10px;

    &--amount {
      padding-right: 40px;

      @include media-sm {
        margin: 0;
        padding: 0;
      }
    }
    &--period {
      padding-left: 40px;

      @include media-sm {
        padding-left: 0;
      }
    }
    &--date {
      align-self: flex-start;
    }
  }
  &__field legend {
    margin-bottom: 10px;
  }
  &__radiogroup {
    @include media-xs {
      flex-direction: column;

      label {
        margin-bottom: 10px;

        + label {
          margin-left: 0;
        }
      }
    }
  }
  & &__radio {
    white-space: normal;

    @include media-xs {
      margin-bottom: 8px;
    }
    .formControlLabel-label {
      text-transform: uppercase;
    }
  }
  &__checkbox-wrapper {
    align-self: flex-end;
    margin-left: auto;
  }
  &__checkbox {
    margin-top: 15px;
    padding-left: 40px;

    @include media-sm {
      padding-left: 0;
    }
    .checkbox-checked {
      /* [Playdoit]
      border-color: color(primary, main);
      background-color: color(primary, main);*/

      &::before {
        color: var(--body-text-light-color); // [Playdoit]
      }
    }
  }
  &__spacer {
    margin: 0 -20px;
    width: calc(100% + 40px);

    > .limit-form__item {
      margin: 0;
      padding: 10px 20px;
    }
  }
  &__notifications {
    text-align: left;

    .notification-root {
      &:not(:last-child) {
        margin-bottom: 10px !important;
      }
    }
  }
}
