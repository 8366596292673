.balance__button {
  opacity: 1;

  .button-spinner {
    display: none;
  }
  span {
    text-transform: none;
  }
  @include isMozillaFirefox {
    span {
      height: 100%;
    }
  }
}
.balance__popover {
  min-width: 300px;
  margin-top: 12px;
  padding: 10px;
  overflow: visible;
  border: var(--balance-popover-border);
  border-radius: 0;
  background:  var(--balance-popover-background);
  text-align: center;

  &::before,
  &::after {
    content: '';
    right: calc(50% - 5px);
    bottom: 100%;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--balance-popover-background);

    @include media-md {
      right: 40px; // [Playdoit]
    }
  }
  &::before {
    margin-bottom: 1px;
    border-bottom-color: var(--balance-popover-border-color);
  }
  .header__deposit {
    margin: 10px 0;
    display: none;

    @include media-md {
      display: inline-block;
    }
  }

  .balance {
    &__user {
      display: flex;
    }
    &__username,
    &__title {
      flex: 1;

      &::before {
        @include svg-icon;
        font-size: 24px;
        margin-right: 5px;
      }
    }
    &__row {
      display: flex;
    }
    &__username,
    &__title,
    &__value {
      display: flex;
      padding: 10px 5px;
      align-items: center;
    }
    &__title {
      flex-wrap: wrap;
    }
    &__value {
      font-weight: $font-weight-alt;
    }
    &__username::before {
      content: '\0045';
    }
    &__row--real .balance__title::before {
      content: '\004d';
    }
    &__row--promo .balance__title::before {
      content: '\0047';
    }
    &__row--betting-bonus .balance__title::before {
      content: '\002c';
    }
    &__row--live-casino-bonus .balance__title::before {
      content: '\220f';
    }
    &__row--cashback .balance__title::before {
      content: '\0067';
    }
    &__row--saving-cash .balance__title::before {
      content: '\0071';
    }
    &__row--free-spins .balance__title::before {
      content: '\006a';
    }
    &__row--loyalty {
      text-decoration: none;

      &.bronze {
        color: color(loyalty-statuses, bronze);
      }
      &.silver {
        color: color(loyalty-statuses, silver);
      }
      &.gold {
        color: color(loyalty-statuses, gold);
      }
      &.platinum {
        color: color(loyalty-statuses, platinum);
      }
      .balance__title::before {
        content: '\006e';
      }
    }
    &__row--live-casino-bonus-winnings {
      .balance__title,
      .balance__value {
        padding: {
          top: unset;
        }
        margin: {
          top: -5px;
        };
        color: var(--text-color-main);
        font-size: 12px;
      }

      .balance__title {
        padding-left: 34px;
      }

      .markdown-root {
        p {
          margin: unset;
        }
      }
    }

    &__row--award-points {
      .balance__title::before {
        content: '\004a';
      }
    }
    &__row--wagering-requirement {
      .balance__title {
        &::before {
          content: '\0046';
        }
        span {
          margin-top: 5px;
          font-size: 0.75em;
        }
      }
      .balance__value {
        flex: 1;
      }
    }
    &__free-spins-update-button {
      display: flex;
      min-width: 0;
      min-height: 0;
      margin-left: 5px;
      padding: 0;
      line-height: 0;
      letter-spacing: normal;
      color: color(text, main);
      border: 0;
      background: none;

      .button-label {
        font-size: 0;
      }
      &::before {
        @include svg-icon;
        content: '\0077';
        font-size: 20px;
      }
      &:hover {
        &::before {
          color: color(accent, light);
        }
      }
      &.button-loading {
        animation: rotate 1s linear infinite;
        color: color(accent, light);
      }

      svg {
        opacity: 0;
      }
    }
  }
}
