.home-menu {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  overflow-x: auto;
  text-align: center;
  transition: all $main-transition-time;

  &.swiper-root {
    position: relative;
    overflow-x: hidden;
    padding: 0 20px;

    .swiper-wrapper {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 100%;
    }

    .swiper-prev,
    .swiper-next {
      @include size(30px, 100%);
      position: absolute;
      z-index: 2;
      top: 0;

      &::before {
        @include svg-icon;
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        color: color(primary, accent);
        content: '\0062';
        font-size: 10px;
        transition: opacity $main-transition-time;
      }

      &.is-disabled {
        pointer-events: none;

        &::before {
          opacity: .1;
        }
      }
    }
    .swiper-prev {
      left: 0;
      background-image: linear-gradient(to right, var(--home-menu-swiper-from) 66%, var(--home-menu-swiper-to) 100%);

      &::before {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
    .swiper-next {
      right: 0;
      background-image: linear-gradient(to left, var(--home-menu-swiper-from) 66%, var(--home-menu-swiper-to) 100%);

      &::before {
        transform: translate(-50%, -50%);
      }
    }
  }

  .settings-menu__item-override {
    padding: 0 10px; // [Playdoit]
    transform: scale(1);
    transition: all $main-transition-time;

    &--search-modal {
      // margin-left: auto; [Playdoit]

      .button-root {
        min-width: 0;
        padding: 0;

        &:hover {
          background: none;

          .button-label {
            &::before {
              color: color(primary, main);
            }
          }
        }
        .button-label {
          // [Playdoit]
          display: flex;
          flex-direction: column;
          padding: 6px 0;
          line-height: 25px;

          &::before {
            @include svg-icon;
            content: '\0059';
            font-size: 20px;
            font-weight: $font-weight-alt;
            transition: $main-transition-time;
          }
          @include media-md {
            line-height: 17px;
          }
        }
      }
    }
    &--search {
      flex: 1;
      text-align: right;
      transform-origin: right;
    }
  }

  &__wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: $header-collapsed-height;
    z-index: 20;
    border: none;
    background-color: var(--home-menu-background);
    transition: all 0.3s;
    transform-origin: top;

    @include media(null, $size-md) {
      &--scrolled {
        transform: scaleY(0.8);

        .settings-menu__item-override {
          transform: scaleX(0.8);
        }
      }
    }
  }

  &__inner {
    position: relative;
    padding: 0;
  }
  a {
    display: inline-block;
    margin: 0;
    padding: 20px 0;
    position: relative;
    text-decoration: none;
    font-size: 14px;
    font-weight: $font-weight-base;
    white-space: nowrap;
    color: var(--home-menu-item-color);
    transition: color $main-transition-time;

    @include media-sm {
      padding: 10px 0;
      font-size: 0;
    }
    &::before {
      @include svg-icon;
      content: '\0078';
      display: block;
      margin-bottom: 4px;
      color: var(--home-menu-icon-color);
      font-size: 21px;
      transition: color $main-transition-time;

      @include media-sm {
        margin-bottom: 0;
      }
    }
    &:hover,
    &.active {
      color: color(primary, main);

      &::before {
        color: color(accent, main); // [PlaydoitMx]
      }
    }
    &[href*="popular"] {
      &::before {
        content: '\0079';
      }
    }
    &[href*="slots"] {
      &::before {
        content: '\007a';
      }
    }
    &[href*="jackpot"] {
      &::before {
        content: '\0030';
      }
    }
    &[href*="table"] {
      &::before {
        content: '\0031';
        font-size: 22px;
      }
    }
    &[href*="all"] {
      &::before {
        content: '\0032';
        font-size: 22px;
      }
    }
    &[href*="favourite"] {
      &::before {
        content: '\0058';
        font-size: 22px;
      }
    }
    &[href*="liveBlackjack"] {
      &::before {
        content: '\002d';
      }
    }
    &[href*="livePoker"] {
      &::before {
        content: '\002e';
      }
    }
    &[href*="liveRoulette"] {
      &::before {
        content: '\002f';
      }
    }
    &[href*="liveBaccarat"] {
      &::before {
        content: '\005b';
      }
    }
    &[href*="liveAll"] {
      &::before {
        content: '\005c';
      }
    }
    &[href*="promo"] {
      &::before {
        content: '\007e';
      }
    }

    &.game-search-button {
      flex: 1;

      &::before {
        @include svg-icon;
        content: '\0059';
        vertical-align: middle;
      }
      &::after {
        display: none;
      }
    }
  }
}

