.home-games {
  display: flex;
  flex-direction: column;
  align-items: start;
  flex: 1;
  margin-bottom: 25px;

  .mobile & {
    padding: 0 6px 45px;
  }

  .games-groups-container {
    margin: unset;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .aside-casino-block {
    flex-basis: 200px;
    flex-shrink: 0;
    flex-grow: 0;
    padding-top: 6px;

    @include media-md {
      top: 45px;
    }
    @include media-sm {
      display: none;
    }

    &:empty {
      display: none;
    }
  }
}

.game-list__games--grid {
  .game-list__games-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0;

    @include media-xs {
      grid-template-columns: 1fr 1fr !important;
    }

    & > .game-thumbnail {
      width: 100%;
      margin: 0;

      @media (min-width: 481px) {
        &:nth-child(14n+1),
        &:nth-child(14n+11) {
          grid-column: span 2;
          grid-row: span 2;

          & .game-thumbnail__title,
          & .game-thumbnail__rtp {
            font-size: 20px;
          }

          .desktop #root[data-url="/all-games"] & {
            grid-column: auto;
            grid-row: auto;

            & .game-thumbnail__title {
              font-size: 16px;
              @include media-lg {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
