.notification-root {
  flex-wrap: nowrap;
  flex: 0 0 auto;
  min-width: 50%;
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px !important;
  padding: 0;
  align-items: center;
  border: 1px solid color(primary, main);
  border-radius: 0;
  background: var(--notification-background);
  box-shadow: none;
  text-align: left;

  &::before {
    @include svg-icon;
    content: '\0041';
    display: flex;
    align-items: center;
    align-self: stretch;
    padding: 15px;
    font-size: 24px;
    color: color(primary, light); // [Playdoit]
    background: color(primary, main);
  }

  p {
    margin: 0;
    font-size: 12px;
  }

  ol {
    padding-left: 13px;
  }

  .notification-message {
    padding: 10px 20px;
    font-size: 12px;
    color: var(--notification-color);
  }

  .notification-action {
    margin-right: 0;
    padding-left: 0;
    color: color(text, main);
    align-self: baseline;

    button {
      @include close-button;
    }
  }
}
.notification-error {
  border-color: var(--error);

  &::before {
    content: '\0042';
    color: color(primary, light);
    background: var(--error);
  }
}
.notification-success {
  border-color: var(--success);

  &::before {
    content: '\0043';
    color: color(primary, light);
    background: var(--success);
  }
}
.notification-warning {
  border-color: var(--warning);

  &::before {
    content: '\0044';
    color: color(primary, light);
    background: var(--warning);
  }
}
.notifications {
  position: fixed;
  top: 5px;
  right: 10px;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 40%;
  pointer-events: none;

  @include media-md {
    width: 60%;
  }
  @include media-sm {
    right: 10px;
    left: 10px;
    width: auto;
  }
  .notification {
    display: flex;
    align-items: flex-end;
    min-width: 100%;
    margin-top: 5px;
    padding-bottom: 10px;
    overflow: hidden;
    pointer-events: all;

    &:last-child {
      padding-bottom: 0;
    }
    &-root {
      margin-bottom: 0;
    }
    ul {
      padding-left: 10px;
    }
    .notification-action {
      .material-icons {
        font-size: inherit;
      }
    }
  }
}

// [Playdoit] Custom styles for notifications
.available-bonuses-notification {
  &::before {
    @include svg-icon("custom");
    content: '\0044'
  }
}
.notification-info {
  border-color: color(primary, dark);

  &::before {
    color: color(primary, light);
    background: color(primary, dark);
  }
}
