.footer {
  &__license {
    padding: 25px 0 0;

    &-text {
      max-width: 80%;
      margin: 30px auto 15px;
      padding: 0 5px 30px;
      font-size: 14px;
      border-bottom: 1px solid var(--footer-borders-color);
    }
  }
  &__version {
    font-size: 14px;
  }

  &__bottom-section {
    padding-top: 0;
  }

  .custom-footer__item-social__links {
    display: flex;
    justify-content: center;
    a {
      display: block;
      margin-left: 18px;
      @include svg-icon("custom");
      font-size: 28px;
      text-decoration: none;
      color: color(primary, dark);

      &.twitter::before {
        content: '\0021';
      }
      &.facebook::before {
        content: '\0063';
      }
      &.instagram::before {
        content: '\0059';
      }
      &.pinterest::before {
        content: '\005a';
      }
      &.youtube::before {
        content: '\0062';
      }
      &:hover {
        color: var(--link-default-color);
      }
    }
  }
  .custom-footer__item-logos-patrocinador {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 80%;
    padding-bottom: 32px;
    border-bottom: 1px solid var(--footer-borders-color);

    @include media-md {
      max-width: 100%;
    }

    &__title {
      flex: 1 1 100%;
      margin-bottom: 24px;
      font-size: 32px;
      text-align: center;
    }

    img {
      max-height: 110px;
    }
  }
  .custom-footer__item-logos-segob {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 17.5px auto;
    text-align: center;
    font-size: 0;

    img {
      max-width: 180px;
      max-height: 60px;
      margin: 5px 12px;

      body.dark-theme & {
        filter: brightness(0) invert(0.5);
      }
    }
  }
}
