.not-found-page {
  min-height: 70vh;
  margin-top: 0;
  margin-bottom: -40px;
  padding-top: 120px;
  @include media-md {
    background: url('/cms/img/bg-mobile.jpg') center/cover no-repeat;
  }

  &__content {
    margin: 0 0 0 15%;
    & > img {
      display: block;
      width: 280px;
      @include media-md {
        margin: 0 auto;
        width: 150px
      }
    }

    @include media-md {
      margin: 0 auto;
    }
  }
  &__title {
    max-width: 350px;
    font: 60px 'Raleway-ExtraBold', sans-serif;
  }
  &__link {
    margin-top: 15px;
    margin-left: 7px;
    padding: 10px 15px;
    font-size: 16px;
    background-color: color(secondary, main);
  }
}
