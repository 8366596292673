.main-decorator__footer {
  position: relative;
}
.footer {
  position: relative;
  margin: auto;
  background: var(--footer-background);
  color: var(--footer-color);

  &__top-section {
    background-color: var(--footer-top-section-background);
  }
  &__bottom-section {
    padding-top: 25px;

    @include media-sm {
      padding-top: 15px;
    }

    #root:not([data-url='/']):not([data-url^='/group/']):not([data-url$='-games']) & {
      padding-bottom: 0;
    }
  }
  &__license {
    padding: 8px 14px;
  }
  &__license-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;

    a {
      color: color(footer, link);

      &:hover {
        color: color(footer, link);
      }
    }
    p {
      margin: 0;
    }
  }
  .container {
    max-width: 1020px;
  }

  &__version {
    padding: 10px 0;
    font-size: 11px;
    text-align: center;
  }
}
