.validation-form--group--account {
  .radioGroup-root {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 10px 20px;
    padding: 0;

    .formControlLabel-label {
      display: flex;
      justify-content: flex-start;
      padding: 28px 16px;
      font-size: 14px;
    }

    .form-control {
      &__passport {
        span:before {
          content: '\0025';
          @include svg-icon("custom");
          margin-right: 12px;
          font-size: 28px;
        }
      }

      &__identificationCard {
        span:before {
          content: '\0024';
          @include svg-icon("custom");
          margin-right: 12px;
          font-size: 32px;
        }
      }

      &__residencePermit {
        flex: 0;
        min-width: 50%;

        span:before {
          content: '\0026';
          @include svg-icon("custom");
          margin-right: 12px;
          font-size: 32px;
        }
      }
    }
    @include media-sm {
      flex-direction: column;
      align-items: inherit;
      gap: 0;
    }
  }

  .radioGroup-root label + label span {
    margin-top: 10px;
  }

  .subtitle {
    text-transform: initial;
  }
}
