.forgot-password {
  .container {
    max-width: 800px;
    margin-top: 15px;
    padding: 30px;
    background: var(--static-page-background);
  }
  .button-accent {
    margin-top: 20px;
  }
  &__title {
    text-align: center;
  }
  &__submit {
    text-align: center;
  }
  &__receive-email {
    padding-top: 30px;
    text-align: center;
  }
  &__sent-link {
    padding: 50px 0;
    text-align: center;
  }
}
