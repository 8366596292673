.balance__popover {
  .header__deposit {
    background: color(accent, secondary);
  }

  .balance {
    &__row {
      & > *::before {
        @include svg-icon("custom");
      }
      &--real .balance__title::before {
        content: '\007a';
      }
      &--balance .balance__title::before {
        content: '\0047';
      }
      &--promo .balance__title::before {
        content: '\0056';
      }
      &--free-spins .balance__title::before {
        content: '\0075';
      }
      &--betting-bonus .balance__title::before {
        content: '\004a';
      }
      &--live-casino-bonus .balance__title::before {
        content: '\0039';
      }
      &--wagering-requirement {
        .balance__title::before {
          content: '\0055';
        }
      }
    }
    &__button > span,
    &__value {
      font-weight: normal;
      font-family: 'Roboto', sans-serif;
    }
    &__username::before {
      @include svg-icon("custom");
      content: '\0057';
    }
  }
}
.balance__button {
  span {
    font-family: 'Roboto', sans-serif;
  }
}
