.protection-login-form {
  .input-underline {
    background: var(--protection-login-form-input-background);
  }
  .protection-login__button {
    display: block;
    width: auto;
    margin: auto;
    color: color(primary, light);
    background: color(secondary, main);
  }
}
