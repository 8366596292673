.gd-window {
  background: none;

  &__control-btn {
    .button-label {
      background-color: color(accent, main);
      color: color(primary, light);

      &::before {
        border-color: transparent transparent transparent color(accent, main);
      }
    }
  }
  &__title {
    font-size: 16px;
    color: color(text,light);
  }
  &__control-btn::before {
    color: color(text,light);
  }
  &__footer {
    .game-provider-license {
      display: none;
    }
  }
}
