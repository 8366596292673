.typography {
  font-size: 14px;
  color: var(--text-color-main);

  h1 {
    position: relative;
    margin: 20px 0 44px;
    font-size: 30px;
    letter-spacing: .05em;
  }
  .underlined-title {
    position: relative;
    h1 {
      display: inline-block;

      &::before {
        content: '';
        position: absolute;
        bottom: -16px;
        left: 0;
        width: 100%;
        height: 6px;
        background-color: color(primary, main);
      }
      @include media-xs {
        font-size: 22px;
        margin-bottom: 14px;
      }
    }
    + .border {
      margin-top: 20px;
      text-align: justify; // [Playdoit]
    }
    &__logoApp {
      img {
        max-width: 2%;
      }
      @include media-xs {
        img {
          max-width: 5%;
        }
      }

    }
  }
  h2 {
    margin: 16px 0 4px;

    @include media-xs {
      font-size: 16px;
    }
  }
  h3 {
    font-size: 15px;
    font-weight: $font-weight-alt;
    text-transform: uppercase;

    @include media-xs {
      font-size: 14px;
    }
  }
  p {
    font-size: 14px;
    line-height: 19px;
  }
  ul,
  ol {
    list-style-type: none;

    li {
      margin-bottom: 4px;
      position: relative;
      font-size: 14px;

      &::before {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        position: absolute;
        left: -21px;
        top: 6px;
        background-color: var(--link-default-color);
      }
    }
  }
  ul.custom {
    list-style-type: none;

    li {

      &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        left: -15px;
        top: 6px;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid color(primary, main);
        background-color: transparent;
      }
    }
  }
  img {
    max-width: 100%;
  }
  .border {
    // [Playdoit]
    padding: 0;
  }
}
